var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import NativeCollectionTitle from 'src/components/TableView/TableCell/NativeCollectionTitle';
import { CollectionTableType } from 'src/shared';
import { collectionTableCellDataSelector } from 'src/state';
import RollupCellHOC from 'src/components/OptimizedTableView/Cell/RollupCell/RollupCellHOC';
import { isEqual } from 'lodash';
import AddressCellHOC from './AddressCell/AddressCellHOC';
import DateTimeCellHOC from './DateTimeCell/DateTimeCellHOC';
import FileCell from './FileCell/FileCell';
import FormulaCellHOC from './FormulaCell/FormulaCellHOC';
import NumberCellHOC from './NumberCellHOC';
import PhoneInputCellHOC from './PhoneInputCell/PhoneInputCellHOC';
import PlaceholderCell from './PlaceholderCell/PlaceholderCell';
import SelectionCellHOC from './SelectionCell/SelectionCellHOC';
import SignatureCellHOC from './SignatureCell/SignatureCellHOC';
import TextCellHOC from './TextCell/TextCellHOC';
import ButtonCell from './ButtonCell/ButtonCell';
import { canEditCell, isEmptyCellData } from './util';
import RatingCell from './RatingCell/RatingCell';
export var PreFetchedCellHOC = memo(function (props) {
    return _jsx(CellHOC, __assign({}, props));
}, function (prevProps, nextProps) {
    return (prevProps.cellPos === nextProps.cellPos &&
        prevProps.blockId === nextProps.blockId &&
        prevProps.isClicked === nextProps.isClicked &&
        isEqual(prevProps.value, nextProps.value));
});
PreFetchedCellHOC.displayName = 'PreFetchedCellHOC';
var PositionedCellHOC = function (props) {
    var cellPos = props.cellPos, collectionTableType = props.collectionTableType;
    var value = useRecoilValue(collectionTableCellDataSelector(__assign({ type: collectionTableType }, cellPos)));
    return _jsx(CellHOC, __assign({ value: value }, props));
};
var CellHOC = function (_a) {
    var _b;
    var value = _a.value, cellPos = _a.cellPos, editCell = _a.editCell, blockId = _a.blockId, canEditDefault = _a.canEdit, canOnlyUpdateEmpty = _a.canOnlyUpdateEmpty, collectionTableType = _a.collectionTableType, isClicked = _a.isClicked, setIsClicked = _a.setIsClicked, openBulkButtonClickActionConfirmation = _a.openBulkButtonClickActionConfirmation;
    var memoValue = useMemo(function () { return value; }, [value]);
    if (memoValue === undefined || !cellPos)
        return null;
    var isEmptyCell = isEmptyCellData(memoValue);
    var canEdit = canEditCell(canEditDefault, canOnlyUpdateEmpty, collectionTableType, memoValue);
    if (collectionTableType !== CollectionTableType.TABLE &&
        !isClicked &&
        isEmptyCell &&
        !((_b = Object.values(memoValue)[0]) === null || _b === void 0 ? void 0 : _b.data) &&
        !('rating' in memoValue) // Don't show placeholder for file cell and rating cell
    ) {
        return _jsx(PlaceholderCell, { cellPos: cellPos, canEdit: canEdit, setIsClicked: setIsClicked });
    }
    if ('text' in memoValue) {
        var text = memoValue.text;
        var value_1 = text.value, color = text.color;
        return (_jsx(TextCellHOC, { text: value_1 || '', editCell: function (newValues) {
                editCell(cellPos, newValues);
            }, cellPos: cellPos, color: color, canEdit: canEdit, isClicked: isClicked, setIsClicked: setIsClicked, isModalCell: collectionTableType !== CollectionTableType.TABLE }));
    }
    else if ('title' in memoValue) {
        var title = memoValue.title;
        var value_2 = title.value, avatar = title.avatar, color = title.color;
        return _jsx(NativeCollectionTitle, { data: { title: value_2 !== null && value_2 !== void 0 ? value_2 : '', avatar: avatar }, color: color });
    }
    else if ('amount' in memoValue) {
        return (_jsx(NumberCellHOC, { amount: {
                symbol: memoValue.amount.symbol,
                value: memoValue.amount.value,
            }, editCell: function (newValues) {
                editCell(cellPos, newValues);
            }, cellPos: cellPos, canEdit: canEdit, isClicked: isClicked, setIsClicked: setIsClicked, isModalCell: collectionTableType !== CollectionTableType.TABLE }));
    }
    else if ('address' in memoValue) {
        return (_jsx(AddressCellHOC, { address: memoValue.address || '', editCell: function (newValues) {
                editCell(cellPos, newValues);
            }, canEdit: canEdit, isClicked: isClicked, setIsClicked: setIsClicked }));
    }
    else if ('time' in memoValue) {
        return (_jsx(DateTimeCellHOC, { time: memoValue.time || [], editCell: function (newValues, timeZone) {
                editCell(cellPos, newValues, timeZone);
            }, cellPos: cellPos, canEdit: canEdit, isClicked: isClicked, setIsClicked: setIsClicked, collectionTableType: collectionTableType }));
    }
    else if ('selections' in memoValue) {
        return (_jsx(SelectionCellHOC, { selections: memoValue.selections.map(function (opt) {
                return { id: opt.id, text: opt.label, color: opt.color || '' };
            }) || [], editCell: function (newValues) {
                editCell(cellPos, newValues.map(function (val) {
                    var _a;
                    return { id: val.id, name: (_a = val.text) !== null && _a !== void 0 ? _a : '', color: val.color || '' };
                }));
            }, cellPos: cellPos, blockId: blockId, canEdit: canEdit, isClicked: isClicked, setIsClicked: setIsClicked }));
    }
    else if ('users' in memoValue) {
        return (_jsx(SelectionCellHOC, { selections: memoValue.users.map(function (user) {
                return {
                    id: user.rid,
                    text: user.name,
                    color: '',
                    userAvatar: user.iconUrl,
                };
            }) || [], editCell: function (newValues) {
                editCell(cellPos, newValues.map(function (val) {
                    var _a;
                    return { id: val.id, name: (_a = val.text) !== null && _a !== void 0 ? _a : '', avatarUrl: val.userAvatar };
                }));
            }, cellPos: cellPos, blockId: blockId, canEdit: canEdit, isClicked: isClicked, setIsClicked: setIsClicked }));
    }
    else if ('files' in memoValue) {
        return (_jsx(FileCell, { files: memoValue.files.data || [], editCell: function (newValues) {
                editCell(cellPos, newValues);
                setIsClicked(false);
            }, mode: memoValue.files.mode, canEdit: canEdit, linkTemplateEnabled: memoValue.files.canLinkToTemplate, isModalCell: collectionTableType !== CollectionTableType.TABLE }));
    }
    else if ('signature' in memoValue) {
        return (_jsx(SignatureCellHOC, { signature: memoValue.signature, editCell: function (newValue) {
                editCell(cellPos, [newValue]);
            }, canEdit: canEdit, isClicked: isClicked, setIsClicked: setIsClicked }));
    }
    else if ('formula' in memoValue) {
        return _jsx(FormulaCellHOC, { cellPos: cellPos, canEdit: canEdit });
    }
    else if ('phone' in memoValue) {
        return (_jsx(PhoneInputCellHOC, { phone: memoValue.phone || '', editCell: function (newValues) {
                editCell(cellPos, newValues);
            }, canEdit: canEdit, isClicked: isClicked, setIsClicked: setIsClicked }));
    }
    else if ('button' in memoValue) {
        return (_jsx(ButtonCell, { cellPos: cellPos, canEdit: canEdit, isModalCell: collectionTableType !== CollectionTableType.TABLE, openBulkButtonClickActionConfirmation: openBulkButtonClickActionConfirmation }));
    }
    else if ('rollup' in memoValue) {
        var rollup = memoValue.rollup;
        return (_jsx(RollupCellHOC, { cellPos: cellPos, value: rollup.value, numerator: rollup.numerator || rollup.denominator
                ? Number.parseInt(rollup.numerator || '0')
                : null, denominator: rollup.denominator || rollup.numerator
                ? Number.parseInt(rollup.denominator || '0')
                : null }));
    }
    else if ('rating' in memoValue) {
        var rating = memoValue.rating;
        return (_jsx(RatingCell, { value: rating.value, cellPos: cellPos, canEdit: canEdit, editCell: function (newValues) {
                editCell(cellPos, newValues);
            }, setIsClicked: setIsClicked }));
    }
    return null;
};
export default React.memo(PositionedCellHOC, function (prevProps, nextProps) {
    return (prevProps.cellPos === nextProps.cellPos &&
        prevProps.blockId === nextProps.blockId &&
        prevProps.isClicked === nextProps.isClicked);
});
