var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilValue } from 'recoil';
import { pageItemSelector } from 'src/state';
import { usePageApi } from 'src/hooks/api';
import { ButtonType } from '../../../shared';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
import './ButtonBlock.scss';
import ButtonBlockModal from '../NewBlockDropdown/ButtonBlockModal';
var ButtonBlock = function (props) {
    var blockId = props.blockId, moveBlockUpDown = props.moveBlockUpDown;
    var _a = usePageApi(), useListBlockQuery = _a.useListBlockQuery, useUpdatePageBlock = _a.useUpdatePageBlock;
    var _b = useListBlockQuery({
        blockId: blockId,
        contentType: 'button',
    }), data = _b.data, isFetched = _b.isFetched;
    var _c = useState(), buttonBlockData = _c[0], setButtonBlockData = _c[1];
    useEffect(function () {
        if (data && data[0] && data[0].button) {
            setButtonBlockData(data[0].button);
        }
    }, [data]);
    var _d = useState(false), showButtonModal = _d[0], setShowButtonModal = _d[1];
    var onEditBlock = useCallback(function () {
        if (buttonBlockData) {
            setShowButtonModal(true);
        }
    }, [buttonBlockData]);
    var updateButtonBlock = useCallback(function (values) {
        useUpdatePageBlock.mutate({
            blockId: blockId,
            content: {
                buttonContent: values,
            },
        });
        setButtonBlockData(values);
        setShowButtonModal(false);
    }, [blockId, useUpdatePageBlock]);
    var menuChildren = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { icon: _jsx(EditOutlined, { className: "EditIcon" }), onClick: onEditBlock, children: "Edit" }, "edit"), _jsx(Menu.Divider, {})] }));
    }, [onEditBlock]);
    return !isFetched ? (_jsx(_Fragment, { children: " " })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "buttonBlockWrapper", children: [_jsx(HoverOptionButtons, { data: { blockId: blockId }, moveBlockUpDown: moveBlockUpDown, menuChildren: menuChildren, isNonTitleBlock: true }), _jsxs("div", { className: "buttonBlock", children: [(buttonBlockData === null || buttonBlockData === void 0 ? void 0 : buttonBlockData.type) === ButtonType.PAGE && (_jsx(PageTypeButton, { data: buttonBlockData })), (buttonBlockData === null || buttonBlockData === void 0 ? void 0 : buttonBlockData.type) === ButtonType.FORM && (_jsx(FormTypeButton, { data: buttonBlockData })), (buttonBlockData === null || buttonBlockData === void 0 ? void 0 : buttonBlockData.type) === ButtonType.WEB_LINK && (_jsx(WebLinkTypeButton, { data: buttonBlockData })), (buttonBlockData === null || buttonBlockData === void 0 ? void 0 : buttonBlockData.type) === ButtonType.HIGH_RADIUS_SSO_LINK && (_jsx(EmbeddedSSOTypeButton, { data: buttonBlockData }))] })] }), _jsx(ButtonBlockModal, { visible: showButtonModal, content: buttonBlockData, onOk: updateButtonBlock, onCancel: function () { return setShowButtonModal(false); }, isEdit: true })] }));
};
var PageTypeButton = function (_a) {
    var data = _a.data;
    var page = useRecoilValue(pageItemSelector(data.link));
    var link = useMemo(function () {
        return "/home/page/".concat(page === null || page === void 0 ? void 0 : page.parentId, "/").concat(page === null || page === void 0 ? void 0 : page.id);
    }, [page === null || page === void 0 ? void 0 : page.id, page === null || page === void 0 ? void 0 : page.parentId]);
    return (_jsx("a", { href: link, target: "_blank", rel: "noopener noreferrer", children: data.label }));
};
var FormTypeButton = function (_a) {
    var data = _a.data;
    var link = "/internal-form/".concat(data.link);
    return (_jsx("a", { href: link, target: "_blank", rel: "noopener noreferrer", children: data.label }));
};
var WebLinkTypeButton = function (_a) {
    var data = _a.data;
    var link = data.link.includes('://') ? data.link : "//".concat(data.link);
    return (_jsx("a", { href: link, target: "_blank", rel: "noopener noreferrer", children: data.label }));
};
var EmbeddedSSOTypeButton = function (_a) {
    var data = _a.data;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var baseUrl, token, endpoint, response, htmlContent, newTab, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 2:
                    token = _a.sent();
                    endpoint = "".concat(baseUrl).concat(data.link);
                    return [4 /*yield*/, fetch(endpoint, {
                            method: 'POST',
                            headers: {
                                Authorization: "Bearer ".concat(token),
                            },
                        })];
                case 3:
                    response = _a.sent();
                    if (!response.ok) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, response.text()];
                case 4:
                    htmlContent = _a.sent();
                    newTab = window.open();
                    if (newTab) {
                        newTab.document.open();
                        newTab.document.write(htmlContent);
                        newTab.document.close();
                    }
                    return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    message.error('Oops! Something went wrong.');
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("button", { onClick: handleClick, className: "embedded-sso-button", children: data.label }));
};
export default React.memo(ButtonBlock);
