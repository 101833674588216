import { jsx as _jsx } from "react/jsx-runtime";
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
var SearchBar = function (_a) {
    var onSearch = _a.onSearch, queryValue = _a.queryValue, _b = _a.placeholder, placeholder = _b === void 0 ? 'Search...' : _b;
    var handleInputChange = function (e) {
        onSearch(e.target.value);
    };
    return (_jsx(Input, { value: queryValue, onChange: handleInputChange, placeholder: placeholder, prefix: _jsx(SearchOutlined, {}), allowClear: true, style: {
            maxWidth: '500px',
            width: '100%',
            borderRadius: '10px',
        } }));
};
export default SearchBar;
