import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { Tabs } from 'antd';
import { CHAT_FEATURE_GATING_KEY } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { useRecoilState, useRecoilValue } from 'recoil';
import { detailModalInstancesState, feedSiderActiveTabState, ifUserHasGeneralPermission, isTopDetailModal, } from 'src/state';
import ChatInRecordDetailModal from 'src/components/RecordDetailModal/ChatInRecordDetailModal';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import HelpActionWrapper from '../Header/HelpDrawer/HelpActionWrapper';
import { HelpArticleType, HelpDrawerLinkedArticleMap } from '../Header/HelpDrawer/Constant';
import './FeedContainer.scss';
import ActivityFeed from './ActivityFeed/ActivityFeed';
import RecordCommentFeed from './CommentFeed/RecordCommentFeed';
import AccountCommentFeed from './CommentFeed/AccountCommentFeed';
export var FeedTabType;
(function (FeedTabType) {
    FeedTabType["CHAT"] = "CHAT";
    FeedTabType["COMMENTS"] = "COMMENTS";
    FeedTabType["ACTIVITY"] = "ACTIVITY";
})(FeedTabType || (FeedTabType = {}));
var TabPane = Tabs.TabPane;
var FeedContainer = function (_a) {
    var collapsed = _a.collapsed, recordId = _a.recordId, _b = _a.isModal, isModal = _b === void 0 ? false : _b, userModalChatDataSource = _a.userModalChatDataSource;
    var articleId = HelpDrawerLinkedArticleMap[HelpArticleType.ACTIVITY_FEED];
    var hasCommentsPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.COMMENTS));
    var isChatFeatureEnabled = useRecoilValue(featureEnableStatusState(CHAT_FEATURE_GATING_KEY));
    var hasChatPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.CHAT));
    var isChatEnableInFeedSider = isChatFeatureEnabled && hasChatPermission && isModal && userModalChatDataSource && recordId;
    var hasActivityPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.ACTIVITY_HISTORY));
    var _c = useRecoilState(feedSiderActiveTabState(isModal ? 'MODAL' : 'PAGE')), activeTab = _c[0], setActiveTab = _c[1];
    var isTopModal = useRecoilValue(isTopDetailModal(recordId !== null && recordId !== void 0 ? recordId : ''));
    var isMainActivityFeed = useRecoilValue(detailModalInstancesState).length === 0;
    var commentComponent = useMemo(function () {
        if (recordId && isModal) {
            return _jsx(RecordCommentFeed, { recordId: recordId });
        }
        else if (!isModal) {
            return _jsx(AccountCommentFeed, {});
        }
        else {
            return null;
        }
    }, [recordId, isModal]);
    var panes = useMemo(function () {
        var availableTabs = [];
        if (isModal) {
            if (isChatEnableInFeedSider) {
                availableTabs.push({
                    title: 'Chat',
                    key: FeedTabType.CHAT,
                    componentToRender: activeTab === FeedTabType.CHAT ? (_jsx(ChatInRecordDetailModal, { dataSource: userModalChatDataSource, recordId: recordId })) : null,
                });
            }
            if (hasCommentsPermission) {
                availableTabs.push({
                    title: 'Comments',
                    key: FeedTabType.COMMENTS,
                    componentToRender: FeedTabType.COMMENTS ? commentComponent : null,
                });
            }
            if (hasActivityPermission) {
                availableTabs.push({
                    title: 'Activity',
                    key: FeedTabType.ACTIVITY,
                    componentToRender: activeTab === FeedTabType.ACTIVITY ? (_jsx(ActivityFeed, { collapsed: collapsed, recordId: recordId })) : null,
                });
            }
        }
        else {
            // For account level feed, only show activity and comments and activity is before comments
            if (hasActivityPermission) {
                availableTabs.push({
                    title: 'Activity',
                    key: FeedTabType.ACTIVITY,
                    componentToRender: activeTab === FeedTabType.ACTIVITY ? (_jsx(ActivityFeed, { collapsed: collapsed, recordId: recordId })) : null,
                });
            }
            if (hasCommentsPermission) {
                availableTabs.push({
                    title: 'Comments',
                    key: FeedTabType.COMMENTS,
                    componentToRender: FeedTabType.COMMENTS ? commentComponent : null,
                });
            }
        }
        return availableTabs;
    }, [
        isModal,
        isChatEnableInFeedSider,
        hasCommentsPermission,
        hasActivityPermission,
        activeTab,
        userModalChatDataSource,
        recordId,
        commentComponent,
        collapsed,
    ]);
    var tabPanes = panes.map(function (pane) {
        return (_jsx(TabPane, { tab: _jsx("span", { className: "font-medium px-2", children: pane.title }), className: "spacer", children: _jsx("div", { className: "spacer text-gray-600", children: pane.componentToRender }) }, pane.key));
    });
    var defaultActiveTab = useMemo(function () {
        if (panes.length === 0) {
            return undefined;
        }
        else {
            if (isModal) {
                if (isChatEnableInFeedSider) {
                    return FeedTabType.CHAT;
                }
                else if (hasCommentsPermission) {
                    return FeedTabType.COMMENTS;
                }
                else if (hasActivityPermission) {
                    return FeedTabType.ACTIVITY;
                }
            }
            else {
                if (hasActivityPermission) {
                    return FeedTabType.ACTIVITY;
                }
                else if (hasCommentsPermission) {
                    return FeedTabType.COMMENTS;
                }
            }
            return undefined;
        }
    }, [
        hasActivityPermission,
        hasCommentsPermission,
        isChatEnableInFeedSider,
        panes.length,
        isModal,
    ]);
    useEffect(function () {
        if (isMainActivityFeed || isTopModal) {
            // Set active tab to default value when undefined or current tab is not supported in current sider
            if (!activeTab) {
                setActiveTab(defaultActiveTab);
            }
            else {
                var currentAvailableTabs = panes.map(function (pane) { return pane.key; });
                if (!currentAvailableTabs.includes(activeTab)) {
                    setActiveTab(defaultActiveTab);
                }
            }
        }
    }, [activeTab, defaultActiveTab, panes, setActiveTab, isTopModal, isMainActivityFeed]);
    return (_jsxs("div", { className: "activityFeedWrapper bg-gray-40", children: [_jsxs("div", { className: "mb-2 flex items-center", children: [_jsx("div", { className: "activityFeedTitle", children: "Feed" }), _jsx(HelpActionWrapper, { articleId: articleId, children: _jsx(HelpIcon, { className: "ml-2 text-black" }) })] }), _jsx(Tabs, { className: "selectorTabs", activeKey: activeTab, onChange: function (key) { return setActiveTab(key); }, children: tabPanes })] }));
};
export default React.memo(FeedContainer);
