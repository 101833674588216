import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { cloneDeep } from 'lodash';
import { Select, Skeleton, Switch } from 'antd';
import { FRONTEND_TOTAL_BILL, BILL_OVER_TIME_DROPDOWN, OVERTIME_FOUNDATIONS_SETTINGS, } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { useAutomation } from 'src/hooks/api';
import { CollectionType } from 'src/shared';
import { appSessionState } from 'src/state';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { AutomationFilterType } from 'src/components/Automations/Interface';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import { useSetting } from '../../../../hooks/api/useSettingApi';
import { OvertimeCalculationSetting } from '../Interface';
import OvertimeGroup from './OvertimeGroup';
import './OvertimeSection.scss';
var Option = Select.Option;
var OvertimeSection = function () {
    var _a;
    var _b = CompliancePageState.useContainer(), overtimeGroups = _b.overtimeGroups, addOvertimeGroup = _b.addOvertimeGroup, setOvertimeGroups = _b.setOvertimeGroups, overtimeCalculationSetting = _b.overtimeCalculationSetting, setOvertimeCalculationSetting = _b.setOvertimeCalculationSetting, setOvertimeExclusionConditionSchemas = _b.setOvertimeExclusionConditionSchemas, billOvertimeCalculationSetting = _b.billOvertimeCalculationSetting, setBillOvertimeCalculationSetting = _b.setBillOvertimeCalculationSetting, weeklyOvertimeAverageEnabledSetting = _b.weeklyOvertimeAverageEnabledSetting, setWeeklyOvertimeAverageEnabledSetting = _b.setWeeklyOvertimeAverageEnabledSetting;
    var _c = useSetting(), useGetOvertimeSettingData = _c.useGetOvertimeSettingData, useGetOvertimePolicyUserCounts = _c.useGetOvertimePolicyUserCounts, useGetOvertimeCalculationSetting = _c.useGetOvertimeCalculationSetting, useGetBillOvertimeCalculationSetting = _c.useGetBillOvertimeCalculationSetting, useGetWeeklyAverageOvertimeEnabled = _c.useGetWeeklyAverageOvertimeEnabled;
    var defaultOvertimeCalculationSetting = useGetOvertimeCalculationSetting().data;
    var defaultBillOvertimeCalculationSetting = useGetBillOvertimeCalculationSetting().data;
    var overtimePolicies = useGetOvertimeSettingData().data;
    var overtimePolicyUserCounts = useGetOvertimePolicyUserCounts().data;
    var convertMsToHours = useCallback(function (threshold) { return parseInt(threshold) / 3600000; }, []);
    var isTotalBillEnabled = useRecoilValue(featureEnableStatusState(FRONTEND_TOTAL_BILL));
    var showOvertimeBillEnabled = useRecoilValue(featureEnableStatusState(BILL_OVER_TIME_DROPDOWN));
    var isOvertimeFoundationsSettings = useRecoilValue(featureEnableStatusState(OVERTIME_FOUNDATIONS_SETTINGS));
    var isFetching = useGetWeeklyAverageOvertimeEnabled(function (data) {
        setWeeklyOvertimeAverageEnabledSetting(data);
    }).isFetching;
    useEffect(function () {
        if (defaultOvertimeCalculationSetting) {
            setOvertimeCalculationSetting(defaultOvertimeCalculationSetting);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultOvertimeCalculationSetting]);
    useEffect(function () {
        if (defaultBillOvertimeCalculationSetting) {
            setBillOvertimeCalculationSetting(defaultBillOvertimeCalculationSetting);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultBillOvertimeCalculationSetting]);
    useEffect(function () {
        if (overtimePolicies) {
            // convert threshold to hours
            var tempData = cloneDeep(overtimePolicies);
            tempData.forEach(function (item) {
                item.wageOvertimeRule.dailySettings.forEach(function (rule) { return (rule.threshold = convertMsToHours(rule.threshold).toString()); });
            });
            tempData.forEach(function (item) {
                item.wageOvertimeRule.weeklySettings.forEach(function (rule) { return (rule.threshold = convertMsToHours(rule.threshold).toString()); });
            });
            setOvertimeGroups(tempData);
        }
    }, [convertMsToHours, overtimePolicies, setOvertimeGroups]);
    var accountId = ((_a = useRecoilValue(appSessionState)) !== null && _a !== void 0 ? _a : {}).accountId;
    var shiftCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.SHIFT));
    var useGetAutomationFilterOptionsQuery = useAutomation().useGetAutomationFilterOptionsQuery;
    var automationFilter = useGetAutomationFilterOptionsQuery({
        body: {
            accountId: accountId || '',
            collectionId: shiftCollectionId || '',
        },
        options: {
            enabled: !!shiftCollectionId,
        },
    }).data;
    useEffect(function () {
        if (automationFilter) {
            var schemas = automationFilter.schemaGroups.filter(function (group) { return group.type === AutomationFilterType.RECORD; });
            setOvertimeExclusionConditionSchemas(schemas);
        }
    }, [automationFilter, setOvertimeExclusionConditionSchemas]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "SectionWrapper", children: [_jsx("div", { className: "SectionTitle", children: "Overtime Calculation" }), _jsx("div", { className: "SectionSubTitle", children: "Choose how you want to apply overtime calculation" }), _jsxs("div", { className: "flex gap-2 mt-3", children: [_jsxs("label", { className: "flex flex-col", children: [_jsx("span", { className: "font-semibold", children: "Pay Overtime" }), _jsxs(Select, { className: "w-32", value: overtimeCalculationSetting, onChange: setOvertimeCalculationSetting, children: [_jsx(Option, { value: OvertimeCalculationSetting.REGULAR, children: "Regular" }), _jsx(Option, { value: OvertimeCalculationSetting.PER_LOCATION, children: "Per Location" })] })] }), (showOvertimeBillEnabled || isTotalBillEnabled) && (_jsxs("label", { className: "flex flex-col", children: [_jsx("span", { className: "font-semibold", children: "Bill Overtime" }), _jsxs(Select, { className: "w-32", value: billOvertimeCalculationSetting, onChange: setBillOvertimeCalculationSetting, children: [_jsx(Option, { value: OvertimeCalculationSetting.NONE, children: "None" }), _jsx(Option, { value: OvertimeCalculationSetting.REGULAR, children: "Regular" }), _jsx(Option, { value: OvertimeCalculationSetting.PER_LOCATION, children: "Per Location" })] })] }))] })] }), isOvertimeFoundationsSettings && (_jsxs("div", { className: "SectionWrapper flex items-center justify-between", children: [_jsxs("div", { children: [_jsx("div", { className: "SectionTitle", children: "Use Weighted Averages For Pay" }), _jsx("div", { className: "SectionSubTitle", children: "Overtime is calculated based on a weighted average of different hourly rates the employee might have worked on during the week." })] }), _jsx("div", { children: isFetching ? (_jsx(Skeleton, {})) : (_jsx(Switch, { checked: weeklyOvertimeAverageEnabledSetting, onChange: function (checked) {
                                return setWeeklyOvertimeAverageEnabledSetting(checked);
                            } })) })] })), _jsxs("div", { className: "SectionWrapper", children: [_jsx("div", { className: "SectionTitle", children: "Overtime Policies" }), _jsx("div", { className: "SectionSubTitle", children: "Add your overtime policies and apply policies to your staff." }), overtimeGroups.map(function (group, index) {
                        var _a;
                        var count = (_a = overtimePolicyUserCounts === null || overtimePolicyUserCounts === void 0 ? void 0 : overtimePolicyUserCounts.find(function (obj) { return obj.overtimePolicyId == group.id; })) === null || _a === void 0 ? void 0 : _a.count;
                        return (_jsx(OvertimeGroup, { group: group, groupIndex: index, userCount: count }, group.id));
                    }), _jsx("div", { className: "ComplianceAddGroupBtn SettingsGrayButton mt-3", onClick: function () { return addOvertimeGroup(); }, children: "New Overtime Policy" })] })] }));
};
export default React.memo(OvertimeSection);
