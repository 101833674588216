var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback } from 'react';
import { produce } from 'immer';
import { cloneDeep, merge, uniqBy } from 'lodash';
import moment from 'moment';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { SchemaWithNewValuesType } from 'src/components/RecordDetailModal/RecordDetailModal';
import { CollectionTableType, CollectionType, EmitterEventType, NativePath, RecordSchemaType, ValueDataType, } from 'src/shared';
import { accountIdState, tableFiltersSelector, collectionTableCellState, collectionTableNonSchemaMutationsState, collectionTableSchemaMutationsState, collectionTableSchemaSelector, collectionTableState, collectionTableTypeSelector, tableCollectionIdSelector, collectionTableRecordState, tableSelectedViewIdState, blockTimeZoneState, collectionTableSchemasState, payRateValueShouldManuallyUpdateState, billRateValueShouldManuallyUpdateState, detailModalInstancesState, } from 'src/state';
import Emitter from 'src/utils/EventEmitter';
import { getMutateSchemaProperties, getSpecialMutateSchemaProperties, } from 'src/services/CollectionDataService';
import { getNativePathKey, isEditRecordForTimeOffApproval } from 'src/utils/Collection';
import { toast } from 'react-toastify';
import { useArchiveOptions } from 'src/components/ArchiveOptions/useArchiveOptions';
import usePayRateV2Api from 'src/components/Settings/PayRate/usePayRateV2Api';
import { PayRuleAdjustmentField } from 'src/components/Settings/PayRate/interface';
import { usePageApi } from '../api';
import { useRefetchCollection } from './useRefetchCollection';
import { usePage } from './usePage';
import { useSchemaComponent } from './useSchema';
import { usePayComponent } from './usePay';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useRecord = function () {
    var accountId = useRecoilValue(accountIdState);
    var _a = usePageApi(), preSaveNewRecord = _a.preSaveNewRecord, updateCollectionRecords = _a.updateCollectionRecords, bulkUpdateCollectionRecords = _a.bulkUpdateCollectionRecords, bulkModifyCollectionRecords = _a.bulkModifyCollectionRecords, useDeleteCollectionRecords = _a.useDeleteCollectionRecords, updateRecordColor = _a.updateRecordColor;
    var openUnassignShiftsModal = usePage().openUnassignShiftsModal;
    var refetchCollection = useRefetchCollection();
    var refetchColumnOptions = useSchemaComponent().refetchColumnOptions;
    var refetchPayViewDataForShiftAndTimeoffRecordMutation = usePayComponent().refetchPayViewDataForShiftAndTimeoffRecordMutation;
    var handleArchiveOptionsState = useArchiveOptions().handleArchiveOptionsState;
    var _b = usePayRateV2Api(), useUpdateShiftPayManually = _b.useUpdateShiftPayManually, useBulkUpdateShiftPayManually = _b.useBulkUpdateShiftPayManually;
    var checkOverwritePaySchemaProperties = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (recordId, schemaProperties, collectionId) {
            var collectionSchemas = snapshot
                .getLoadable(collectionTableSchemasState(collectionId))
                .valueMaybe();
            var paySchemasToUpdate = [];
            var _loop_1 = function (schema) {
                var schemaInfo = collectionSchemas === null || collectionSchemas === void 0 ? void 0 : collectionSchemas.find(function (collectionSchema) {
                    return collectionSchema.id === schema.schemaId;
                });
                // Support for pay rate/bill rate/custom currency schema
                if (schemaInfo &&
                    (schemaInfo.nativePath === NativePath.SHIFT_WAGE ||
                        schemaInfo.nativePath === NativePath.SHIFT_BILL_RATE ||
                        schemaInfo.type === ValueDataType.CURRENCY)) {
                    if (schemaInfo.nativePath === NativePath.SHIFT_WAGE ||
                        schemaInfo.nativePath === NativePath.SHIFT_BILL_RATE) {
                        if (!schema.number) {
                            return "continue";
                        }
                        var value = schema.number;
                        var filed = schemaInfo.nativePath === NativePath.SHIFT_WAGE
                            ? PayRuleAdjustmentField.PAY_RATE
                            : PayRuleAdjustmentField.BILL_RATE;
                        var cellPos = {
                            collectionId: collectionId,
                            schemaId: schema.schemaId,
                            recordId: recordId,
                        };
                        if (schemaInfo.nativePath === NativePath.SHIFT_WAGE) {
                            var shouldManuallyUpdate = snapshot
                                .getLoadable(payRateValueShouldManuallyUpdateState(cellPos))
                                .valueMaybe();
                            if (shouldManuallyUpdate) {
                                paySchemasToUpdate.push({
                                    schemaId: schema.schemaId,
                                    value: Number(value),
                                    field: filed,
                                });
                            }
                        }
                        if (schemaInfo.nativePath === NativePath.SHIFT_BILL_RATE) {
                            var shouldManuallyUpdate = snapshot
                                .getLoadable(billRateValueShouldManuallyUpdateState(cellPos))
                                .valueMaybe();
                            if (shouldManuallyUpdate) {
                                paySchemasToUpdate.push({
                                    schemaId: schema.schemaId,
                                    value: Number(value),
                                    field: filed,
                                });
                            }
                        }
                    }
                    else {
                        var value = (schema === null || schema === void 0 ? void 0 : schema.collectionValue).amount;
                        if (!value) {
                            return "continue";
                        }
                        paySchemasToUpdate.push({
                            schemaId: schema.schemaId,
                            value: Number(value),
                            field: PayRuleAdjustmentField.CUSTOM,
                        });
                    }
                }
            };
            for (var _i = 0, schemaProperties_1 = schemaProperties; _i < schemaProperties_1.length; _i++) {
                var schema = schemaProperties_1[_i];
                _loop_1(schema);
            }
            return paySchemasToUpdate;
        };
    }, []);
    var getPreSaveRequestBody = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId, dstCollectionId, 
        // For reverse collection
        referenceCollectionId, referenceRid, schemaId, standardComponent) {
            var _a;
            var tableId = blockId || standardComponent || schemaId || '';
            var sourceCollectionId = dstCollectionId ||
                snapshot.getLoadable(tableCollectionIdSelector(tableId)).valueMaybe();
            if (sourceCollectionId === undefined) {
                throw new Error('sourceCollectionId is undefined');
            }
            var filters = (_a = snapshot
                .getLoadable(tableFiltersSelector(tableId))
                .valueMaybe()) === null || _a === void 0 ? void 0 : _a.filter(function (f) { return !f.secondaryFilter; });
            var viewId = snapshot.getLoadable(tableSelectedViewIdState(tableId)).valueMaybe();
            var requestForReverseCollection = {
                targetCollectionId: sourceCollectionId,
                sourceCollectionId: referenceCollectionId,
                sourceRecordId: referenceRid,
                refSchemaId: schemaId,
                filters: filters,
            };
            var requestForPageCollection = {
                targetCollectionId: sourceCollectionId,
                collectionViewId: viewId,
                filters: filters,
            };
            return referenceRid ? requestForReverseCollection : requestForPageCollection;
        };
    }, []);
    var onCreateNewRecord = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (blockId, dstCollectionId, schemaDefaults, 
        // For reverse collection
        referenceCollectionId, referenceRecordId, schemaId, standardComponent) { return __awaiter(void 0, void 0, void 0, function () {
            var request, resp, defaultTimeZone, oldInstances;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        request = getPreSaveRequestBody(blockId, dstCollectionId, referenceCollectionId, referenceRecordId, schemaId, standardComponent);
                        return [4 /*yield*/, preSaveNewRecord(request)];
                    case 1:
                        resp = _b.sent();
                        if (!resp) {
                            return [2 /*return*/];
                        }
                        defaultTimeZone = (_a = new URLSearchParams(location.search).get('timezone')) !== null && _a !== void 0 ? _a : snapshot.getLoadable(blockTimeZoneState(blockId)).getValue();
                        oldInstances = snapshot.getLoadable(detailModalInstancesState).getValue();
                        set(detailModalInstancesState, __spreadArray(__spreadArray([], oldInstances, true), [
                            {
                                recordId: resp.mainRecord.rows[0].recordId,
                                defaultModalData: resp,
                                bulkEditData: undefined,
                                schemaDefaults: schemaDefaults,
                                referenceRid: referenceRecordId,
                                defaultTimeZone: defaultTimeZone,
                                isCreatingNew: true,
                            },
                        ], false));
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPreSaveRequestBody]);
    // Add record in board view column with prefilled column value
    var onCreateNewRecordInColumn = useCallback(function (blockId, dstCollectionId, schemaId, newValue, 
    // For reverse collection
    referenceCollectionId, referenceRecordId, reverseCollectionSchemaId) { return __awaiter(void 0, void 0, void 0, function () {
        var schemaDefaults, canPrefillSchema;
        return __generator(this, function (_a) {
            schemaDefaults = [];
            canPrefillSchema = true;
            if (canPrefillSchema && newValue) {
                schemaDefaults.push({
                    schemaId: schemaId,
                    newValues: [newValue],
                });
            }
            return [2 /*return*/, onCreateNewRecord(blockId, dstCollectionId, schemaDefaults, referenceCollectionId, referenceRecordId, reverseCollectionSchemaId)];
        });
    }); }, [onCreateNewRecord]);
    /** Updates cell UI and adds to schema mutations state */
    var editRecordCell = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set, reset = _a.reset;
        return function (collectionTableType, cellPos, newValues, //! Date values must be an ISO string
        timeZone, ignoreSave, recordIds) {
            if (recordIds === void 0) { recordIds = [cellPos.recordId]; }
            var release = snapshot.retain();
            try {
                var collectionId_1 = cellPos.collectionId, schemaId = cellPos.schemaId;
                var _loop_2 = function (recordId) {
                    var schema = snapshot
                        .getLoadable(collectionTableSchemaSelector({
                        collectionId: collectionId_1,
                        schemaId: schemaId,
                    }))
                        .valueMaybe();
                    if (!schema)
                        return { value: void 0 };
                    var isFileData = schema.type === ValueDataType.FILE ||
                        (newValues[0] &&
                            typeof newValues[0] === 'object' &&
                            'url' in newValues[0]);
                    var isSelectOption = newValues[0] && typeof newValues[0] === 'object' && 'id' in newValues[0];
                    var newCellValue = isFileData
                        ? newValues
                        : isSelectOption
                            ? newValues
                            : newValues;
                    var newCellData = {
                        content: isFileData || isSelectOption
                            ? null
                            : newCellValue[0]
                                ? newCellValue[0]
                                : null,
                        options: isSelectOption ? newCellValue : [],
                        files: isFileData ? newCellValue : [],
                        versionedOptions: [],
                    };
                    // Mutate cell state to update UI
                    set(collectionTableCellState({
                        type: collectionTableType,
                        collectionId: collectionId_1,
                        schemaId: schemaId,
                        recordId: recordId,
                    }), newCellData);
                    if (ignoreSave)
                        return { value: void 0 };
                    // Update mutation state
                    if (schema.nativePath === NativePath.USER_PIN) {
                        set(collectionTableNonSchemaMutationsState({
                            type: collectionTableType,
                            collectionId: collectionId_1,
                        }), function (prev) {
                            return uniqBy(__spreadArray([
                                {
                                    schema: NativePath.USER_PIN,
                                    text: newCellValue[0],
                                }
                            ], prev, true), 'schema');
                        });
                    }
                    else {
                        set(collectionTableSchemaMutationsState({
                            type: collectionTableType,
                            collectionId: collectionId_1,
                        }), function (prev) {
                            var temp = cloneDeep(prev);
                            var schemaPropertyToMutate = [];
                            if (isFileData) {
                                schemaPropertyToMutate = getSpecialMutateSchemaProperties([
                                    {
                                        type: SchemaWithNewValuesType.SPECIAL,
                                        schema: schema,
                                        newValues: newCellValue,
                                    },
                                ]);
                            }
                            else {
                                //! Handle date schema (convert to formatted date)
                                if (getNativePathKey(schema.type) ===
                                    RecordSchemaType.TIMESTAMP ||
                                    schema.type === ValueDataType.DATETIME) {
                                    if (schema.nativePath === NativePath.USER_DOB) {
                                        newCellValue = [
                                            moment(newCellValue[0]).format('YYYY-MM-DD'),
                                        ];
                                    }
                                    else {
                                        newCellValue = [
                                            timeZone
                                                ? moment(newCellValue[0])
                                                    .tz(timeZone)
                                                    .format('MMM D, YYYY h:mm A')
                                                : moment(newCellValue[0]).format('MMM D, YYYY h:mm A'),
                                        ];
                                    }
                                }
                                schemaPropertyToMutate = getMutateSchemaProperties([
                                    {
                                        type: SchemaWithNewValuesType.NORMAL,
                                        schema: schema,
                                        newValues: isSelectOption
                                            ? newCellValue.map(function (option) { return option.id; })
                                            : newCellValue,
                                    },
                                ], timeZone);
                            }
                            var duplicateIndex = temp.findIndex(function (schemaProperty) { return schemaProperty.schemaId === schema.id; });
                            if (duplicateIndex !== -1) {
                                temp.splice(duplicateIndex, 1);
                            }
                            return __spreadArray(__spreadArray([], temp, true), schemaPropertyToMutate, true);
                        });
                    }
                };
                for (var _i = 0, recordIds_1 = recordIds; _i < recordIds_1.length; _i++) {
                    var recordId = recordIds_1[_i];
                    var state_1 = _loop_2(recordId);
                    if (typeof state_1 === "object")
                        return state_1.value;
                }
                //! Immediately update record for TABLE type
                if (collectionTableType === CollectionTableType.TABLE) {
                    var dataSource_1 = {
                        type: collectionTableType,
                        collectionId: collectionId_1,
                    };
                    var collectionType_1 = snapshot
                        .getLoadable(collectionTableTypeSelector(dataSource_1))
                        .valueMaybe();
                    if (!collectionType_1)
                        return;
                    var prevTableState_1 = snapshot
                        .getLoadable(collectionTableState(dataSource_1))
                        .valueMaybe();
                    setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                        var isSuccess;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, editRecord(dataSource_1, collectionType_1, recordIds)
                                    // Handle error
                                ];
                                case 1:
                                    isSuccess = _a.sent();
                                    // Handle error
                                    if (!isSuccess && prevTableState_1) {
                                        set(collectionTableState(dataSource_1), prevTableState_1);
                                        reset(collectionTableSchemaMutationsState(dataSource_1));
                                        reset(collectionTableNonSchemaMutationsState(dataSource_1));
                                        // Refetch for bulk edit as there may be partial success
                                        if (recordIds.length > 1) {
                                            refetchCollection(collectionId_1);
                                            toast('Failed to update 1 or more records. Please try again later.', {
                                                type: 'error',
                                                position: toast.POSITION.BOTTOM_CENTER,
                                            });
                                        }
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); }, 0);
                }
            }
            finally {
                release();
            }
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var editRecord = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, reset = _a.reset, set = _a.set;
        return function (collectionDataSource, collectionType, recordIds
        // onArchive?: () => Promise<void>
        ) { return __awaiter(void 0, void 0, void 0, function () {
            var release, schemasToMutate, nonSchemasToMutate, collectionId, requestBody_1, resp_1, paySchemasToUpdate, request, paySchemasToUpdate, request, record, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        release = snapshot.retain();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 17, 18, 19]);
                        schemasToMutate = snapshot
                            .getLoadable(collectionTableSchemaMutationsState(collectionDataSource))
                            .valueMaybe();
                        nonSchemasToMutate = snapshot
                            .getLoadable(collectionTableNonSchemaMutationsState(collectionDataSource))
                            .valueMaybe();
                        if (!(schemasToMutate === null || schemasToMutate === void 0 ? void 0 : schemasToMutate.length) && !(nonSchemasToMutate === null || nonSchemasToMutate === void 0 ? void 0 : nonSchemasToMutate.length)) {
                            return [2 /*return*/];
                        }
                        collectionId = collectionDataSource.collectionId;
                        requestBody_1 = {
                            accountId: accountId,
                            collectionType: collectionType,
                            collectionId: collectionId,
                            schemaProperties: schemasToMutate,
                            nonSchemaProperties: nonSchemasToMutate,
                        };
                        if (!(recordIds.length === 1)) return [3 /*break*/, 9];
                        requestBody_1 = merge(requestBody_1, {
                            recordId: recordIds[0],
                        });
                        if (!(collectionType === CollectionType.SHIFT)) return [3 /*break*/, 6];
                        paySchemasToUpdate = checkOverwritePaySchemaProperties(recordIds[0], schemasToMutate !== null && schemasToMutate !== void 0 ? schemasToMutate : [], collectionId);
                        if (!(paySchemasToUpdate.length === 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, updateCollectionRecords(requestBody_1)];
                    case 2:
                        resp_1 = _b.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        request = {
                            shiftRid: recordIds[0],
                            schemas: paySchemasToUpdate,
                        };
                        return [4 /*yield*/, useUpdateShiftPayManually.mutateAsync(request, {
                                onSettled: function () {
                                    //Send update record request after updating pay value
                                    resp_1 = updateCollectionRecords(requestBody_1);
                                },
                            })];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, updateCollectionRecords(requestBody_1)];
                    case 7:
                        resp_1 = _b.sent();
                        if (collectionType === CollectionType.TIMEOFF &&
                            isEditRecordForTimeOffApproval(schemasToMutate)) {
                            openUnassignShiftsModal(recordIds[0]);
                        }
                        _b.label = 8;
                    case 8: return [3 /*break*/, 16];
                    case 9:
                        requestBody_1 = merge(requestBody_1, {
                            recordIds: recordIds,
                        });
                        if (!(collectionType === CollectionType.SHIFT)) return [3 /*break*/, 14];
                        paySchemasToUpdate = checkOverwritePaySchemaProperties(recordIds[0], schemasToMutate !== null && schemasToMutate !== void 0 ? schemasToMutate : [], collectionId);
                        if (!(paySchemasToUpdate.length === 0)) return [3 /*break*/, 11];
                        return [4 /*yield*/, bulkUpdateCollectionRecords(requestBody_1)];
                    case 10:
                        resp_1 = _b.sent();
                        return [3 /*break*/, 13];
                    case 11:
                        request = {
                            shiftRids: recordIds,
                            schemas: paySchemasToUpdate,
                        };
                        return [4 /*yield*/, useBulkUpdateShiftPayManually.mutateAsync(request, {
                                onSettled: function () {
                                    //Send bulk update record request after updating pay value
                                    resp_1 = bulkUpdateCollectionRecords(requestBody_1);
                                },
                            })];
                    case 12:
                        _b.sent();
                        _b.label = 13;
                    case 13: return [3 /*break*/, 16];
                    case 14: return [4 /*yield*/, bulkUpdateCollectionRecords(requestBody_1)];
                    case 15:
                        resp_1 = _b.sent();
                        _b.label = 16;
                    case 16:
                        if (resp_1) {
                            if (collectionDataSource.type !== CollectionTableType.TABLE &&
                                recordIds.length === 1) {
                                record = snapshot
                                    .getLoadable(collectionTableRecordState(__assign(__assign({}, collectionDataSource), { recordId: recordIds[0] })))
                                    .valueMaybe();
                                if (record) {
                                    set(collectionTableRecordState(__assign(__assign({}, collectionDataSource), { type: CollectionTableType.TABLE, recordId: recordIds[0] })), record);
                                }
                            }
                            // Refetch collection blocks
                            refetchCollection(collectionId, 'update', schemasToMutate === null || schemasToMutate === void 0 ? void 0 : schemasToMutate.map(function (_a) {
                                var schemaId = _a.schemaId;
                                return schemaId;
                            }));
                            // Refetch snapshot widget when edit record  under dashboard view
                            if (!location.pathname.split('/')[2]) {
                                Emitter.emit(EmitterEventType.REFETCH_DASHBOARD_SNAPSHOT, undefined);
                            }
                            // Refetch pay data when edit shift record under pay
                            refetchPayViewDataForShiftAndTimeoffRecordMutation(collectionType);
                            if (collectionDataSource.type !== CollectionTableType.TABLE) {
                                // Reset modal state
                                reset(collectionTableState(collectionDataSource));
                            }
                            handleArchiveOptionsState(recordIds, collectionType, collectionDataSource);
                            // Reset mutations state
                            reset(collectionTableSchemaMutationsState(collectionDataSource));
                            reset(collectionTableNonSchemaMutationsState(collectionDataSource));
                        }
                        return [2 /*return*/, !!resp_1];
                    case 17:
                        _a = _b.sent();
                        return [2 /*return*/, false];
                    case 18:
                        release();
                        return [7 /*endfinally*/];
                    case 19: return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    var bulkModifyRecords = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, reset = _a.reset;
        return function (collectionId, recordIds, updateType, schemaId, uuids) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionType, requestBody, resp, dataSource;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        collectionType = snapshot
                            .getLoadable(collectionTableTypeSelector({
                            type: CollectionTableType.TABLE,
                            collectionId: collectionId,
                        }))
                            .valueMaybe();
                        if (!collectionType)
                            return [2 /*return*/];
                        requestBody = {
                            accountId: accountId,
                            collectionType: collectionType,
                            collectionId: collectionId,
                            recordIds: recordIds,
                            uuidsProperty: {
                                schemaId: schemaId,
                                uuids: uuids,
                                type: 'uuids',
                            },
                            updateType: updateType,
                        };
                        return [4 /*yield*/, bulkModifyCollectionRecords(requestBody)];
                    case 1:
                        resp = _a.sent();
                        if (resp) {
                            refetchCollection(collectionId);
                        }
                        dataSource = {
                            type: CollectionTableType.TABLE,
                            collectionId: collectionId,
                        };
                        reset(collectionTableSchemaMutationsState(dataSource));
                        reset(collectionTableNonSchemaMutationsState(dataSource));
                        return [2 /*return*/, !!resp];
                }
            });
        }); };
    }, [accountId, bulkModifyCollectionRecords, refetchCollection]);
    var deleteRecordInPage = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (collectionId, recordIds) { return __awaiter(void 0, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, useDeleteCollectionRecords.mutateAsync({ collectionId: collectionId, recordIds: recordIds }, {
                            onSuccess: function () {
                                for (var _i = 0, recordIds_2 = recordIds; _i < recordIds_2.length; _i++) {
                                    var recordId = recordIds_2[_i];
                                    set(collectionTableRecordState({
                                        type: CollectionTableType.TABLE,
                                        collectionId: collectionId,
                                        recordId: recordId,
                                    }), undefined);
                                }
                                refetchCollection(collectionId, 'delete');
                                refetchColumnOptions(undefined, collectionId);
                                var dataSource = {
                                    type: CollectionTableType.TABLE,
                                    collectionId: collectionId,
                                };
                                var collectionType = snapshot
                                    .getLoadable(collectionTableTypeSelector(dataSource))
                                    .valueMaybe();
                                if (!collectionType)
                                    return;
                                // Refetch pay data when delete shift/timeoff record under pay
                                refetchPayViewDataForShiftAndTimeoffRecordMutation(collectionType);
                            },
                        })];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp];
                }
            });
        }); };
    }, [
        refetchCollection,
        refetchColumnOptions,
        refetchPayViewDataForShiftAndTimeoffRecordMutation,
        useDeleteCollectionRecords,
    ]);
    var addColorToSingleRecord = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (collectionId, recordId, color) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionType, resp;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        collectionType = (_a = snapshot
                            .getLoadable(collectionTableTypeSelector({
                            type: CollectionTableType.TABLE,
                            collectionId: collectionId,
                        }))
                            .valueMaybe()) !== null && _a !== void 0 ? _a : snapshot
                            .getLoadable(collectionTableTypeSelector({
                            type: CollectionTableType.DETAIL_MODAL,
                            collectionId: collectionId,
                        }))
                            .valueMaybe();
                        if (!collectionType)
                            return [2 /*return*/];
                        return [4 /*yield*/, updateRecordColor({
                                recordId: recordId,
                                color: color,
                                type: collectionType,
                            })];
                    case 1:
                        resp = _b.sent();
                        if (!resp)
                            return [2 /*return*/];
                        set(collectionTableState({ type: CollectionTableType.TABLE, collectionId: collectionId }), function (prev) {
                            if (!prev)
                                return;
                            var recordRowIndex = prev.rows.findIndex(function (row) { return row.recordId === recordId; });
                            if (recordRowIndex === -1)
                                return prev;
                            var nextState = produce(prev, function (draft) {
                                draft.rows[recordRowIndex].color = color;
                            });
                            return nextState;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var dragUpdateColumnRecordCard = useCallback(function (collectionId, collectionType, recordId, schemasToMutate) { return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    requestBody = {
                        recordId: recordId,
                        accountId: accountId,
                        collectionType: collectionType,
                        collectionId: collectionId,
                        schemaProperties: schemasToMutate,
                        nonSchemaProperties: [],
                    };
                    return [4 /*yield*/, updateCollectionRecords(requestBody)];
                case 1:
                    resp = _b.sent();
                    if (resp) {
                        refetchCollection(collectionId);
                    }
                    return [2 /*return*/, !!resp];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    return {
        getPreSaveRequestBody: getPreSaveRequestBody,
        onCreateNewRecord: onCreateNewRecord,
        onCreateNewRecordInColumn: onCreateNewRecordInColumn,
        editRecordCell: editRecordCell,
        editRecord: editRecord,
        bulkModifyRecords: bulkModifyRecords,
        deleteRecordInPage: deleteRecordInPage,
        addColorToSingleRecord: addColorToSingleRecord,
        dragUpdateColumnRecordCard: dragUpdateColumnRecordCard,
    };
};
export { useRecord as useRecordComponent };
