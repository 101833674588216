var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button, Col, Dropdown, Input, Row } from 'antd';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
import UserImgSrc from '../../../../utils/icons/images/userimg.png';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import { OvertimeRuleType } from '../Interface';
import OvertimeRule from './OvertimeRule';
import OvertimeShiftExclusionSection from './OvertimeShiftExclusionSection';
var OvertimeGroup = function (props) {
    var _a = CompliancePageState.useContainer(), changeOvertimeGroupName = _a.changeOvertimeGroupName, addOvertimeRule = _a.addOvertimeRule, deleteOvertimeGroup = _a.deleteOvertimeGroup, applyToAllUsers = _a.applyToAllUsers, saveOvertimeSettings = _a.saveOvertimeSettings;
    var group = props.group, groupIndex = props.groupIndex, userCount = props.userCount;
    var handleAddOvertimeRule = useCallback(function (type) {
        addOvertimeRule(groupIndex, type);
    }, [addOvertimeRule, groupIndex]);
    var handleApplyToAllUsers = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, groupId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveOvertimeSettings()];
                case 1:
                    resp = _a.sent();
                    if (group.id) {
                        applyToAllUsers(group.id);
                    }
                    else {
                        if (!resp || resp.length === 0)
                            return [2 /*return*/];
                        groupId = resp[groupIndex].id;
                        if (groupId)
                            applyToAllUsers(groupId);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [groupIndex, group.id]);
    var items = [
        {
            key: 'DailyRule',
            label: 'Add New Daily Rule',
            onClick: function () { return handleAddOvertimeRule(OvertimeRuleType.DAILY); },
        },
        {
            key: 'WeeklyRule',
            label: 'Add New Weekly Rule',
            onClick: function () { return handleAddOvertimeRule(OvertimeRuleType.WEEKLY); },
        },
        {
            key: 'DayOfWeekRule',
            label: 'Add New Day of Week Rule',
            onClick: function () { return handleAddOvertimeRule(OvertimeRuleType.DAY_OF_WEEK); },
        },
        {
            key: 'SpecialRule',
            label: 'Add New Special Rule',
            onClick: function () { return handleAddOvertimeRule(OvertimeRuleType.SPECIAL); },
        },
    ];
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "TimeOffPolicyGroup", children: [_jsxs(Row, { className: "TimeoffPolicyHeaderRow", children: [_jsx(Col, { span: 10, children: _jsx(Input, { className: "PolicyNameInput", placeholder: "Enter a name for this group", value: group.name, onChange: function (e) { return changeOvertimeGroupName(groupIndex, e.target.value); } }) }), _jsx(Col, { span: 14, className: "ComplianceDeleteGroup", children: _jsx("div", { className: "GroupRuleDeleteBtn", onClick: function () { return deleteOvertimeGroup(groupIndex, group.id); }, children: _jsx(TrashOutlinedIcon, {}) }) })] }), _jsxs("div", { className: "PolicyWrapper", children: [_jsx(Row, { className: "TimeOffPolicyHeader", children: _jsxs(Col, { flex: "250px", className: "TimeOffNameStatus", children: [_jsx("div", { className: "w-[215px]", children: "Overtime Type" }), _jsx("div", { children: "Rule" })] }) }), group.wageOvertimeRule.dailySettings.map(function (rule, index) {
                            return (_jsx(OvertimeRule, { rule: rule, groupIndex: groupIndex, ruleIndex: index, type: OvertimeRuleType.DAILY }, index));
                        }), group.wageOvertimeRule.weeklySettings.map(function (rule, index) {
                            return (_jsx(OvertimeRule, { rule: rule, groupIndex: groupIndex, ruleIndex: index, type: OvertimeRuleType.WEEKLY }, index));
                        }), group.wageOvertimeRule.dayOfWeekSettings.map(function (rule, index) {
                            return (_jsx(OvertimeRule, { rule: rule, groupIndex: groupIndex, ruleIndex: index, type: OvertimeRuleType.DAY_OF_WEEK }, index));
                        }), group.wageOvertimeRule.specialSettings.map(function (rule, index) {
                            return (_jsx(OvertimeRule, { rule: rule, groupIndex: groupIndex, ruleIndex: index, type: OvertimeRuleType.SPECIAL }, index));
                        }), _jsx(Dropdown, { menu: { items: items }, children: _jsx(Button, { className: "AddSectionRuleBtn", onClick: function (e) { return e.stopPropagation(); }, children: "Add Overtime Rule" }) }), _jsx(OvertimeShiftExclusionSection, { groupIndex: groupIndex }), _jsxs("div", { className: "ApplyToUsersWrapper", children: [_jsxs("div", { className: "LeftSection", children: [_jsx("img", { src: UserImgSrc }), _jsxs("span", { className: "TextHolder", children: [_jsxs("div", { className: "MiniHeader", children: ["Applied to ", userCount !== null && userCount !== void 0 ? userCount : 0, " users"] }), _jsx("div", { className: "Subtext", children: "How do I apply this policy to specific users?" })] })] }), _jsx("div", { className: "RightSection", children: _jsx("div", { className: "WhiteButton", onClick: handleApplyToAllUsers, children: "Apply to all users" }) })] })] })] }) }));
};
export default React.memo(OvertimeGroup);
