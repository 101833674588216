import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, InputNumber, Select, Switch, Tooltip } from 'antd';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { InfoCircleOutlined } from '@ant-design/icons';
import TimeZoneSelector from 'src/components/TimeZoneSelector/TimeZoneSelector';
import ZiraDatePicker from 'src/ui/ZiraDatePicker';
import { find, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { getRulesValidationForField, formatSupportsLocationEmailProcess } from '../utils';
import { PayReportExportType } from '../../Constant';
var Option = Select.Option, OptGroup = Select.OptGroup;
var ExportReportModalContent = function (_a) {
    var recordId = _a.recordId, formInstance = _a.formInstance, selectedFormat = _a.selectedFormat, isFormatTypeDropdownOpen = _a.isFormatTypeDropdownOpen, defaultCSVFormatOptions = _a.defaultCSVFormatOptions, defaultPDFFormatOptions = _a.defaultPDFFormatOptions, exportReportTemplates = _a.exportReportTemplates, payrollEnhancementEnabled = _a.payrollEnhancementEnabled, selectableUsersColumns = _a.selectableUsersColumns, selectedLocationContactSchemaId = _a.selectedLocationContactSchemaId, shouldSendToFacilityContact = _a.shouldSendToFacilityContact, shouldUseCustomTimezone = _a.shouldUseCustomTimezone, reportTimeZone = _a.reportTimeZone, setReportTimeZone = _a.setReportTimeZone, setIsFormatTypeDropdownOpen = _a.setIsFormatTypeDropdownOpen, setSelectedLocationContactSchemaId = _a.setSelectedLocationContactSchemaId, onOpenExistingTemplate = _a.onOpenExistingTemplate;
    var renderReportTemplateOption = useCallback(function (option) {
        return (_jsx(Option, { value: option.id, className: "h-8", children: _jsxs("div", { className: "flex justify-between", children: [_jsx("div", { children: option.name }), _jsx(Tooltip, { title: "Click to edit template", children: _jsx("div", { className: "TemplateEditButton cursor rounded-md px-1 hover:bg-gray-100", onClick: function (e) {
                                e.stopPropagation();
                                onOpenExistingTemplate(option.id);
                            }, children: "Edit" }) })] }) }, option.id));
    }, [onOpenExistingTemplate]);
    return (_jsxs(Form, { layout: "vertical", form: formInstance, requiredMark: false, children: [_jsx("div", { className: "ExportPayrollModalTitle", children: "Export Report" }), _jsx(Form.Item, { name: "selectedFormat", label: _jsx("div", { className: "SelectorTitle", children: "Please select payroll export format" }), initialValue: PayReportExportType.AGGREGATE, children: _jsxs(Select, { className: "ExportPartnerSelector", open: isFormatTypeDropdownOpen, onDropdownVisibleChange: function (open) { return setIsFormatTypeDropdownOpen(open); }, children: [_jsxs(OptGroup, { label: "Export CSV", children: [defaultCSVFormatOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }), !recordId &&
                                    (exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.csvExportTemplates.map(function (option) {
                                        return renderReportTemplateOption(option);
                                    }))] }), (!isEmpty(defaultPDFFormatOptions) ||
                            !isEmpty(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.pdfExportTemplates) ||
                            !isEmpty(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.shiftDetailPdfExportTemplates) ||
                            !isEmpty(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.locationInvoicePdfExportTemplates) ||
                            !isEmpty(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.linkedDataPdfExportTemplates)) && (_jsxs(OptGroup, { label: "Export PDF", children: [defaultPDFFormatOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }), exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.pdfExportTemplates.map(function (option) {
                                    return renderReportTemplateOption(option);
                                }), exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.shiftDetailPdfExportTemplates.map(function (option) {
                                    return renderReportTemplateOption(option);
                                }), exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.locationInvoicePdfExportTemplates.map(function (option) { return renderReportTemplateOption(option); }), exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.linkedDataPdfExportTemplates.map(function (option) {
                                    return renderReportTemplateOption(option);
                                })] }))] }) }), _jsxs(Form.Item, { children: [_jsx(Form.Item, { name: "shouldUseCustomTimezone", label: _jsxs("div", { className: "flex flex-row gap-2", children: [_jsx("div", { className: "SelectorTitle", children: "Choose specific timezone for report" }), _jsx(ZiraTooltip, { title: "Payroll report will show each shift's timezone by default. This option allows you to choose a specific timezone for the report.", children: _jsx(InfoCircleOutlined, {}) })] }), initialValue: false, className: "ExportReportOption mb-2", children: _jsx(Switch, { defaultChecked: false }) }), shouldUseCustomTimezone && (_jsx(TimeZoneSelector, { timezoneValue: reportTimeZone, timezoneOnChange: setReportTimeZone, canEdit: true }))] }), _jsx(Form.Item, { name: "isMilitaryTime", label: _jsx("div", { className: "SelectorTitle", children: "Military Time (24 hour format)" }), initialValue: false, className: "ExportReportOption", children: _jsx(Switch, {}) }), (((!!find(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.locationInvoicePdfExportTemplates, function (template) { return template.id === selectedFormat; }) ||
                formatSupportsLocationEmailProcess(selectedFormat)) &&
                (selectableUsersColumns === null || selectableUsersColumns === void 0 ? void 0 : selectableUsersColumns.length)) ||
                0 > 0) && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "sendToContacts", label: _jsxs("div", { className: "flex flex-row gap-2", children: [_jsx("div", { className: "SelectorTitle", children: "Send Invoices to Location Contacts" }), _jsx(ZiraTooltip, { title: "Each invoice will be emailed to an assigned location contact.", children: _jsx(InfoCircleOutlined, {}) })] }), initialValue: false, className: "ExportReportOption", children: _jsx(Switch, {}) }), shouldSendToFacilityContact == true && (_jsxs("div", { className: "flex flex-row justify-between mb-4 ExportReportOption", children: [_jsx("div", { className: "SelectorTitle", children: "Contacts Column" }), _jsx(Select, { value: selectedLocationContactSchemaId, onChange: setSelectedLocationContactSchemaId, showSearch: false, className: "min-w-[200px]", children: selectableUsersColumns === null || selectableUsersColumns === void 0 ? void 0 : selectableUsersColumns.map(function (schema) { return (_jsx(Option, { value: schema.id, children: schema.name }, schema.id)); }) })] }))] })), !recordId &&
                payrollEnhancementEnabled &&
                !find(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.linkedDataPdfExportTemplates, function (template) { return template.id === selectedFormat; }) && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "exportApprovedOnly", label: _jsx("div", { className: "SelectorTitle", children: "Export Approved Shifts Only" }), initialValue: false, className: "ExportReportOption", children: _jsx(Switch, {}) }), _jsx(Form.Item, { name: "markExportedAsPaid", label: _jsx("div", { className: "SelectorTitle", children: "Mark Exported Shifts As Paid" }), initialValue: false, className: "ExportReportOption", children: _jsx(Switch, {}) }), _jsx(Form.Item, { name: "markExportedAsBilled", label: _jsx("div", { className: "SelectorTitle", children: "Mark Exported Shifts As Billed" }), initialValue: false, className: "ExportReportOption", children: _jsx(Switch, {}) })] })), (!!find(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.locationInvoicePdfExportTemplates, function (template) { return template.id === selectedFormat; }) ||
                selectedFormat === PayReportExportType.NURSECORE_DETAILED_REPORT ||
                selectedFormat === PayReportExportType.SAGE_PAY_CUSTOM_CSV_EXPORT ||
                selectedFormat === PayReportExportType.INTERIM_SCRANTON_REPORT_EXPORT ||
                selectedFormat === PayReportExportType.INTERIM_SCRANTON_REPORT_SHIFTS_EXPORT ||
                selectedFormat === PayReportExportType.BLUESKY_PDF_CUSTOM_REPORT) && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "invoiceNumber", label: _jsx("div", { className: "SelectorTitle", children: "Invoice #" }), className: "ExportReportOption", rules: getRulesValidationForField(selectedFormat, 'invoiceNumber'), children: _jsx(InputNumber, { type: "number", precision: 0 }) }), selectedFormat != PayReportExportType.INTERIM_SCRANTON_REPORT_EXPORT && (_jsx(Form.Item, { name: "invoiceDate", label: _jsx("div", { className: "SelectorTitle", children: "Invoice Date" }), initialValue: selectedFormat === PayReportExportType.SAGE_PAY_CUSTOM_CSV_EXPORT ||
                            selectedFormat ===
                                PayReportExportType.INTERIM_SCRANTON_REPORT_SHIFTS_EXPORT ||
                            selectedFormat === PayReportExportType.BLUESKY_PDF_CUSTOM_REPORT
                            ? undefined
                            : false, className: "ExportReportOption", rules: getRulesValidationForField(selectedFormat, 'invoiceDate'), children: _jsx(ZiraDatePicker, {}) })), (!!find(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.locationInvoicePdfExportTemplates, function (template) { return template.id === selectedFormat; }) ||
                        selectedFormat === PayReportExportType.SAGE_PAY_CUSTOM_CSV_EXPORT) && (_jsx(Form.Item, { name: "invoiceDueDate", label: _jsx("div", { className: "SelectorTitle", children: "Invoice Due Date" }), className: "ExportReportOption", rules: getRulesValidationForField(selectedFormat, 'invoiceDueDate'), children: _jsx(ZiraDatePicker, {}) }))] }))] }));
};
export default ExportReportModalContent;
