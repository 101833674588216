var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Select, TimePicker } from 'antd';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useTimeZone } from 'src/hooks/component';
import { SplitShiftDetailTimezoneType } from './Interface';
var ShiftSplitActionSetting = function (_a) {
    var currentAction = _a.currentAction, updateAction = _a.updateAction;
    var timeZone = useTimeZone().timeZone;
    var time = currentAction.detail.splitTime;
    var timezoneType = currentAction.detail.timezone;
    var updateTime = useCallback(function (time) {
        if (!time)
            return;
        var newAction = cloneDeep(currentAction);
        var newTimeValue = time.tz(timeZone).toISOString();
        var detail = __assign(__assign({}, newAction.detail), { splitTime: newTimeValue });
        newAction.detail = detail;
        updateAction(newAction);
    }, [currentAction, timeZone, updateAction]);
    var updateTimezoneType = useCallback(function (type) {
        var newAction = cloneDeep(currentAction);
        var detail = __assign(__assign({}, newAction.detail), { timezone: type });
        newAction.detail = detail;
        updateAction(newAction);
    }, [currentAction, updateAction]);
    return (_jsxs("div", { children: [_jsx(TimePicker, { value: moment(time).tz(timeZone), use12Hours: true, format: "h:mm A", showNow: false, className: "mr-2", onChange: updateTime }), _jsxs(Select, { value: timezoneType, dropdownMatchSelectWidth: false, onChange: updateTimezoneType, children: [_jsx(Select.Option, { children: "Account Timezone" }, SplitShiftDetailTimezoneType.ACCOUNT), _jsx(Select.Option, { children: "Shift Timezone" }, SplitShiftDetailTimezoneType.SHIFT)] })] }));
};
export default React.memo(ShiftSplitActionSetting);
