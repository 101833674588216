var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from 'react-query';
import { useFetch } from './useFetch';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useTimezoneApi = function () {
    var customFetch = useFetch().request;
    var useGetTimezoneOptions = function (options) {
        return useQuery(['timezoneOptions'], function () {
            return customFetch({
                method: 'GET',
                url: 'timezones/options',
            });
        }, __assign(__assign({}, options), { staleTime: 24 * 60 * 60 * 1000 }));
    };
    return {
        useGetTimezoneOptions: useGetTimezoneOptions,
    };
};
export { useTimezoneApi };
