import { atom, atomFamily, selectorFamily } from 'recoil';
import { localStorageEffect } from './localStorageEffects';
var detailModalInstancesState = atom({
    key: 'detailModalInstancesState',
    default: [],
});
var isTopDetailModal = selectorFamily({
    key: 'isTopDetailModal',
    get: function (recordId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var detailModalInstances = get(detailModalInstancesState);
            return ((_b = detailModalInstances[detailModalInstances.length - 1]) === null || _b === void 0 ? void 0 : _b.recordId) === recordId;
        };
    },
});
var detailModalActiveTab = atomFamily({
    key: 'detailModalActiveTab',
    default: '',
});
var detailModalTabs = atomFamily({
    key: 'detailModalTabs',
    default: [],
});
var detailModalIsFeedVisibleState = atom({
    key: 'detailModalIsFeedVisibleState',
    default: true,
    effects: [localStorageEffect('detailModalIsFeedVisibleState')],
});
var existingDetailModalInfo = atom({
    key: 'existingDetailModalInfo',
    default: undefined,
});
var detailModalSchemaGroups = atomFamily({
    key: 'detailModalSchemaGroups',
    default: [],
});
var feedSiderActiveTabState = atomFamily({
    key: 'feedSiderActiveTabState',
    default: undefined,
});
var tasksReverseCollectionViewState = atom({
    key: 'tasksReverseCollectionViewState',
    default: 'table',
    effects: [localStorageEffect('tasksReverseCollectionViewState')],
});
var shiftDetailModalBreakdownInfoState = atomFamily({
    key: 'shiftDetailModalBreakdownInfo',
    default: [],
});
export { detailModalInstancesState, isTopDetailModal, detailModalActiveTab, existingDetailModalInfo, detailModalSchemaGroups, detailModalTabs, detailModalIsFeedVisibleState, feedSiderActiveTabState, tasksReverseCollectionViewState, shiftDetailModalBreakdownInfoState, };
