var _a, _b, _c;
import { jsx as _jsx } from "react/jsx-runtime";
import { ApprovedShiftsIcon } from 'src/utils/icons/PayrollViewIcons/ApprovedShiftsMetricIcon';
import { ApprovedShiftTagIcon } from 'src/utils/icons/PayrollViewIcons/ApprovedShiftTagIcon';
import { CorrectedShiftsIcon } from 'src/utils/icons/PayrollViewIcons/CorrectedShiftsMetricIcon';
import { CorrectedShiftTagIcon } from 'src/utils/icons/PayrollViewIcons/CorrectedShiftTagIcon';
import { PaidShiftsIcon } from 'src/utils/icons/PayrollViewIcons/PaidShiftsMetricIcon';
import { PaidShiftTagIcon } from 'src/utils/icons/PayrollViewIcons/PaidShiftTagIcon';
import { PendingShiftsIcon } from 'src/utils/icons/PayrollViewIcons/PendingShiftsMetricIcon';
import { PendingShiftTagIcon } from 'src/utils/icons/PayrollViewIcons/PendingShiftTagIcon';
import { PayExportType, ShiftPayrollStatus } from './Interface';
export var PayException;
(function (PayException) {
    PayException["NO_SHOW"] = "NO_SHOW";
    PayException["MISSED_CLOCK_OUT"] = "MISSED_CLOCK_OUT";
    PayException["UNSCHEDULED_SHIFT"] = "UNSCHEDULED_SHIFT";
    PayException["OVERTIME_SHIFT"] = "OVERTIME_SHIFT";
    PayException["EXCESSIVE_TIME"] = "EXCESSIVE_TIME";
})(PayException || (PayException = {}));
export var PayExceptionType;
(function (PayExceptionType) {
    PayExceptionType["NO_SHOW"] = "noShow";
    PayExceptionType["MISSED_CLOCK_OUT"] = "missedClockOut";
    PayExceptionType["UNSCHEDULED_SHIFT"] = "unscheduledShift";
    PayExceptionType["OVERTIME_SHIFT"] = "overtimeShift";
    PayExceptionType["EXCESSIVE_TIME"] = "excessiveTime";
})(PayExceptionType || (PayExceptionType = {}));
export var PayReportExportType;
(function (PayReportExportType) {
    PayReportExportType["AGGREGATE"] = "AGGREGATE";
    PayReportExportType["SHIFT_DETAIL_BY_LOCATION"] = "SHIFT_DETAIL_BY_LOCATION";
    PayReportExportType["SHIFT_DETAIL_BY_USER"] = "SHIFT_DETAIL_BY_USER";
    PayReportExportType["ALL_SHIFT_DETAIL"] = "ALL_SHIFT_DETAIL";
    PayReportExportType["SORTED_SHIFT_DETAIL"] = "SORTED_SHIFT_DETAIL";
    PayReportExportType["QUICKBOOKS_SHIFT_DETAIL"] = "QUICKBOOKS_SHIFT_DETAIL";
    PayReportExportType["QUICKBOOKS_SIMPLE_SHIFT"] = "QUICKBOOKS_SIMPLE_SHIFT";
    PayReportExportType["QUICKBOOKS_EXTENDED_SHIFT_DETAIL"] = "QUICKBOOKS_EXTENDED_SHIFT_DETAIL";
    PayReportExportType["EMPEON_SHIFT_DETAIL"] = "EMPEON_SHIFT_DETAIL";
    PayReportExportType["NETHRIS_SHIFT_DETAIL"] = "NETHRIS_SHIFT_DETAIL";
    PayReportExportType["PAYCHEX_SHIFT_DETAIL"] = "PAYCHEX_SHIFT_DETAIL";
    PayReportExportType["PAYPRO_SHIFT_DETAIL"] = "PAYPRO_SHIFT_DETAIL";
    PayReportExportType["QUICKBOOKS_DESKTOP"] = "QUICKBOOKS_DESKTOP";
    PayReportExportType["USER_AND_LOCATION_PDF_SUMMARY"] = "USER_AND_LOCATION_PDF_SUMMARY";
    PayReportExportType["SHIFT_DETAIL"] = "SHIFT_DETAIL";
    PayReportExportType["PAY_CODE_ALLOCATION"] = "PAY_CODE_ALLOCATION";
    PayReportExportType["NURSECORE_PAYROLL"] = "NURSECORE_PAYROLL";
    PayReportExportType["NURSECORE_DAILY_PAY"] = "NURSECORE_DAILY_PAY";
    PayReportExportType["NURSE_ASSOCIATES_RAPIDPAY"] = "NURSE_ASSOCIATES_RAPIDPAY";
    PayReportExportType["SUBURBAN_ELECTRIC_PAYROLL_REPORT"] = "SUBURBAN_ELECTRIC_PAYROLL_REPORT";
    PayReportExportType["HEALTHCARE_NETWORK_STAFFING_RAPID_PAY"] = "HEALTHCARE_NETWORK_STAFFING_RAPID_PAY";
    PayReportExportType["NURSECORE_PAYROLL_EAST"] = "NURSECORE_PAYROLL_EAST";
    PayReportExportType["NURSECORE_PAYROLL_CENTRAL"] = "NURSECORE_PAYROLL_CENTRAL";
    PayReportExportType["NURSECORE_PAYROLL_WEST"] = "NURSECORE_PAYROLL_WEST";
    PayReportExportType["NURSECORE_DAILY_PAY_EAST"] = "NURSECORE_DAILY_PAY_EAST";
    PayReportExportType["NURSECORE_DAILY_PAY_CENTRAL"] = "NURSECORE_DAILY_PAY_CENTRAL";
    PayReportExportType["NURSECORE_DAILY_PAY_WEST"] = "NURSECORE_DAILY_PAY_WEST";
    PayReportExportType["ALL_SHIFTS_IN_NEXT_THIRTY_DAYS"] = "ALL_SHIFTS_IN_NEXT_THIRTY_DAYS";
    PayReportExportType["CUTTING_EDGE_EVENT_INVOICE"] = "CUTTING_EDGE_EVENT_INVOICE";
    PayReportExportType["CUTTING_EDGE_EVENT_ESTIMATE"] = "CUTTING_EDGE_EVENT_ESTIMATE";
    PayReportExportType["CUTTING_EDGE_EVENT_STAFF_LIST"] = "CUTTING_EDGE_EVENT_STAFF_LIST";
    PayReportExportType["UNITED_STAFFING_TRANSPORTATION"] = "UNITED_STAFFING_TRANSPORTATION";
    PayReportExportType["UNITED_STAFFING_SCHOOL"] = "UNITED_STAFFING_SCHOOL";
    PayReportExportType["SCHOOL_REPORT"] = "SCHOOL_REPORT";
    PayReportExportType["SHYFT_PRO_PAYROLL"] = "SHYFT_PRO_PAYROLL";
    PayReportExportType["PARAPROFESSIONALS_REPORT"] = "PARAPROFESSIONALS_REPORT";
    PayReportExportType["NURSECORE_DETAILED_REPORT"] = "NURSECORE_DETAILED_REPORT";
    PayReportExportType["INTERIM_HEALTHCARE_SEIU_TEMP_REPORT"] = "INTERIM_HEALTHCARE_SEIU_TEMP_REPORT";
    PayReportExportType["INTERIM_HEALTHCARE_LOCAL_12_TEMP_REPORT"] = "INTERIM_HEALTHCARE_LOCAL_12_TEMP_REPORT";
    PayReportExportType["INTERIM_HEALTHCARE_REVENUE_SUMMARY_REPORT"] = "INTERIM_HEALTHCARE_REVENUE_SUMMARY_REPORT";
    PayReportExportType["SHIFT_TASK_REPORT"] = "SHIFT_TASK_REPORT";
    PayReportExportType["SAGE_PAY_CUSTOM_CSV_EXPORT"] = "SAGE_PAY_CUSTOM_CSV_EXPORT";
    PayReportExportType["INTERIM_SCRANTON_REPORT_EXPORT"] = "INTERIM_SCRANTON_REPORT_EXPORT";
    PayReportExportType["INTERIM_SCRANTON_REPORT_SHIFTS_EXPORT"] = "INTERIM_SCRANTON_REPORT_SHIFTS_EXPORT";
    PayReportExportType["BLUESKY_PDF_CUSTOM_REPORT"] = "BLUESKY_PDF_CUSTOM_REPORT";
    PayReportExportType["PROLIANT_PAYROLL_TEMPLATE_CSV_EXPORT"] = "PROLIANT_PAYROLL_TEMPLATE_CSV_EXPORT";
    PayReportExportType["EMPEON_CUSTOM_PAYROLL_REPORT"] = "EMPEON_CUSTOM_PAYROLL_REPORT";
})(PayReportExportType || (PayReportExportType = {}));
export var PayReportExportTemplateType;
(function (PayReportExportTemplateType) {
    PayReportExportTemplateType["PDF"] = "PDF";
    PayReportExportTemplateType["CSV"] = "CSV";
})(PayReportExportTemplateType || (PayReportExportTemplateType = {}));
export var PayReportExportPdfType;
(function (PayReportExportPdfType) {
    PayReportExportPdfType["PDF_PER_USER"] = "PDF_PER_USER";
    PayReportExportPdfType["PDF_PER_LOCATION"] = "PDF_PER_LOCATION";
})(PayReportExportPdfType || (PayReportExportPdfType = {}));
export var pdfExportTypeOptions = [
    {
        value: PayReportExportPdfType.PDF_PER_USER,
        displayValue: 'User',
    },
    {
        value: PayReportExportPdfType.PDF_PER_LOCATION,
        displayValue: 'Location',
    },
];
export var ExportPdfTemplateType;
(function (ExportPdfTemplateType) {
    ExportPdfTemplateType["DEFAULT"] = "DEFAULT";
    ExportPdfTemplateType["SHIFT_DETAIL"] = "SHIFT_DETAIL";
    ExportPdfTemplateType["LOCATION_INVOICE"] = "LOCATION_INVOICE";
    ExportPdfTemplateType["SCHOOL_REPORT"] = "SCHOOL_REPORT";
    ExportPdfTemplateType["LINKED_DATA"] = "LINKED_DATA";
})(ExportPdfTemplateType || (ExportPdfTemplateType = {}));
export var pdfTemplateTypeOptions = [
    {
        value: ExportPdfTemplateType.DEFAULT,
        displayValue: 'Default Attestation',
    },
    {
        value: ExportPdfTemplateType.SHIFT_DETAIL,
        displayValue: 'Individual Shift Detail',
    },
    {
        value: ExportPdfTemplateType.LOCATION_INVOICE,
        displayValue: 'Location Invoice',
    },
    {
        value: ExportPdfTemplateType.LINKED_DATA,
        displayValue: 'Linked Data Report',
    },
];
export var aggregateReportFormatOptions = [
    {
        value: PayReportExportType.AGGREGATE,
        displayValue: 'Aggregate Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.SHIFT_DETAIL_BY_LOCATION,
        displayValue: 'Detailed Report (by Location)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.SHIFT_DETAIL_BY_USER,
        displayValue: 'Detailed Report (by Employee)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.ALL_SHIFT_DETAIL,
        displayValue: 'Detailed Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.SORTED_SHIFT_DETAIL,
        displayValue: 'Sorted Detailed Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.QUICKBOOKS_SHIFT_DETAIL,
        displayValue: 'Quickbooks Detailed Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.QUICKBOOKS_SIMPLE_SHIFT,
        displayValue: 'Quickbooks Simple Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.QUICKBOOKS_EXTENDED_SHIFT_DETAIL,
        displayValue: 'Quickbooks Extended Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.EMPEON_SHIFT_DETAIL,
        displayValue: 'Empeon Detailed Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NETHRIS_SHIFT_DETAIL,
        displayValue: 'Nethris Detailed Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.PAYCHEX_SHIFT_DETAIL,
        displayValue: 'Paychex Detailed Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.PAYPRO_SHIFT_DETAIL,
        displayValue: 'PayPro Detailed Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.QUICKBOOKS_DESKTOP,
        displayValue: 'Quickbooks Desktop Report (All)',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.USER_AND_LOCATION_PDF_SUMMARY,
        displayValue: 'User And Location Summary Report',
        type: PayReportExportTemplateType.CSV,
    },
    // Only certain accounts have access to pay code report
    {
        value: PayReportExportType.PAY_CODE_ALLOCATION,
        displayValue: 'Pay Code Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSECORE_PAYROLL,
        displayValue: 'Nursecore Payroll Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSECORE_DAILY_PAY,
        displayValue: 'Nursecore Daily Pay Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSE_ASSOCIATES_RAPIDPAY,
        displayValue: 'Nurse Associates Rapid Pay Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.SUBURBAN_ELECTRIC_PAYROLL_REPORT,
        displayValue: 'Suburban Electric Payroll Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.HEALTHCARE_NETWORK_STAFFING_RAPID_PAY,
        displayValue: 'Healthcare Network Staffing RapidPay Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSECORE_DAILY_PAY_EAST,
        displayValue: 'Nursecore Daily Pay East Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSECORE_DAILY_PAY_CENTRAL,
        displayValue: 'Nursecore Daily Pay Central Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSECORE_DAILY_PAY_WEST,
        displayValue: 'Nursecore Daily Pay West Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSECORE_PAYROLL_EAST,
        displayValue: 'Nursecore Payroll East Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSECORE_PAYROLL_CENTRAL,
        displayValue: 'Nursecore Payroll Central Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.NURSECORE_PAYROLL_WEST,
        displayValue: 'Nursecore Payroll West Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.ALL_SHIFTS_IN_NEXT_THIRTY_DAYS,
        displayValue: 'All Shifts In Next Thirty Days',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.UNITED_STAFFING_TRANSPORTATION,
        displayValue: 'United Staffing Transportation Report',
        type: PayReportExportTemplateType.PDF,
    },
    {
        value: PayReportExportType.UNITED_STAFFING_SCHOOL,
        displayValue: 'United Staffing School Report',
        type: PayReportExportTemplateType.PDF,
    },
    {
        value: PayReportExportType.SHYFT_PRO_PAYROLL,
        displayValue: 'Shyft Pro Payroll Report',
        type: PayReportExportTemplateType.PDF,
    },
    {
        value: PayReportExportType.PARAPROFESSIONALS_REPORT,
        displayValue: 'Paraprofessional Report',
        type: PayReportExportTemplateType.PDF,
    },
    {
        value: PayReportExportType.NURSECORE_DETAILED_REPORT,
        displayValue: 'Nursecore Detailed Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.INTERIM_HEALTHCARE_SEIU_TEMP_REPORT,
        displayValue: 'SEIU Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.INTERIM_HEALTHCARE_LOCAL_12_TEMP_REPORT,
        displayValue: 'Local 12 Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.INTERIM_HEALTHCARE_REVENUE_SUMMARY_REPORT,
        displayValue: 'Revenue Summary Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.SHIFT_TASK_REPORT,
        displayValue: 'Shift Task Report',
        type: PayReportExportTemplateType.PDF,
    },
    {
        value: PayReportExportType.SAGE_PAY_CUSTOM_CSV_EXPORT,
        displayValue: 'Sage Pay Custom CSV Export',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.INTERIM_SCRANTON_REPORT_EXPORT,
        displayValue: 'Interim Scranton Custom Invoice Report',
        type: PayReportExportTemplateType.PDF,
    },
    {
        value: PayReportExportType.INTERIM_SCRANTON_REPORT_SHIFTS_EXPORT,
        displayValue: 'Interim Scranton Custom shifts Invoice Report',
        type: PayReportExportTemplateType.PDF,
    },
    {
        value: PayReportExportType.BLUESKY_PDF_CUSTOM_REPORT,
        displayValue: 'Grouped By Location Report',
        type: PayReportExportTemplateType.PDF,
    },
    {
        value: PayReportExportType.PROLIANT_PAYROLL_TEMPLATE_CSV_EXPORT,
        displayValue: 'Proliant Payroll Template CSV Export',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.EMPEON_CUSTOM_PAYROLL_REPORT,
        displayValue: 'Empeon Custom Payroll Report',
        type: PayReportExportTemplateType.CSV,
    },
];
export var individualReportFormatOptions = [
    {
        value: PayReportExportType.AGGREGATE,
        displayValue: 'Aggregate Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.SHIFT_DETAIL,
        displayValue: 'Detailed Report',
        type: PayReportExportTemplateType.CSV,
    },
    {
        value: PayReportExportType.SCHOOL_REPORT,
        displayValue: 'School Report',
        type: PayReportExportTemplateType.PDF,
    },
];
export var ImportFeatureGatingType;
(function (ImportFeatureGatingType) {
    ImportFeatureGatingType["IN_HOUSE"] = "in_house";
    ImportFeatureGatingType["FLAT_FILE"] = "flat_file";
})(ImportFeatureGatingType || (ImportFeatureGatingType = {}));
export var payExportOptionsMap = (_a = {},
    _a[PayExportType.REPORT] = {
        name: 'Report',
        description: 'Export payroll in report format (CSV, PDF).',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14"><path stroke="#ffffff" d="M4.18522 2.37195C3.83268 2.41584 3.48403 2.46152 3.13969 2.50382C2.66061 2.56268 2.28199 2.94669 2.2481 3.42818C2.0423 6.35152 2.0423 9.11339 2.2481 12.0367C2.282 12.5182 2.6607 12.9034 3.14169 12.9437C5.78943 13.1657 8.21057 13.1657 10.8583 12.9437C11.3393 12.9034 11.718 12.5182 11.7519 12.0367C11.9577 9.11339 11.9577 6.35152 11.7519 3.42818C11.718 2.94669 11.3394 2.56268 10.8603 2.50382C10.516 2.46152 10.1673 2.41584 9.81478 2.37195"></path><path stroke="#ffffff" stroke-linecap="round" d="M5.09375 6.51562H8.90625"></path><path stroke="#ffffff" stroke-linecap="round" d="M5.09375 9.42188H7.3944"></path><rect width="5.656" height="2.969" x="4.172" y=".75" stroke="#ffffff" stroke-linejoin="round" rx="1.484"></rect></svg>',
    },
    _a[PayExportType.INTEGRATION] = {
        name: 'Integration',
        description: 'Export payroll through your integration partner.',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height="14" width="14"><g><line x1="7" y1="4.81" x2="7" y2="3.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></line><path d="M5.75,8.31c0,.66.53.88,1.25.88s1.25,0,1.25-.88C8.25,7,5.75,7,5.75,5.69c0-.88.53-.88,1.25-.88s1.25.33,1.25.88" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path><line x1="7" y1="9.19" x2="7" y2="10.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></line><circle cx="7" cy="7" r="6.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></circle><path d="M5,3.54a4,4,0,0,0,0,6.92" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9,10.46A4,4,0,0,0,9,3.54" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>',
    },
    _a);
export var shiftPayrollCountMetricCardInfoMap = (_b = {},
    _b[ShiftPayrollStatus.PAID] = {
        displayName: 'Paid',
        icon: _jsx(PaidShiftsIcon, {}),
        cardBgColor: '#2F4DDF',
    },
    _b[ShiftPayrollStatus.APPROVED] = {
        displayName: 'Approved',
        icon: _jsx(ApprovedShiftsIcon, {}),
        cardBgColor: '#00A76F',
    },
    _b[ShiftPayrollStatus.PENDING] = {
        displayName: 'Pending',
        icon: _jsx(PendingShiftsIcon, {}),
        cardBgColor: '#FFC047',
    },
    _b[ShiftPayrollStatus.CORRECTED] = {
        displayName: 'Corrected',
        icon: _jsx(CorrectedShiftsIcon, {}),
        cardBgColor: '#FF4A43',
    },
    _b);
export var shiftPayrollCountTagInfoMap = (_c = {},
    _c[ShiftPayrollStatus.PAID] = {
        displayName: 'Paid',
        color: '#E8F2FC',
        icon: _jsx(PaidShiftTagIcon, { className: "w-[20px] mb-0.5" }),
    },
    _c[ShiftPayrollStatus.APPROVED] = {
        displayName: 'Approved',
        color: '#E5F4EF',
        icon: _jsx(ApprovedShiftTagIcon, { className: "w-[20px] mb-[1px]" }),
    },
    _c[ShiftPayrollStatus.PENDING] = {
        displayName: 'Pending',
        color: '#FFF9ED',
        icon: _jsx(PendingShiftTagIcon, { className: "w-[20px] mb-[3px]" }),
    },
    _c[ShiftPayrollStatus.CORRECTED] = {
        displayName: 'Corrected',
        color: '#FFEDEC',
        icon: _jsx(CorrectedShiftTagIcon, { className: "w-[20px] mb-1" }),
    },
    _c);
export var TEMPLATE_FORM_FIELD_LABEL_CLASS = 'text-xs font-semibold text-gray-400';
