var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton } from 'antd';
import { produce } from 'immer';
import _, { cloneDeep, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import { useRecoilState, useRecoilValue } from 'recoil';
import { blockTimeZoneState, canUpdateFilterOnPageSelector, collectionTableSchemasState, } from 'src/state';
import { useDisplaySettings, useRecordDetail, useWindowSize } from 'src/hooks/component';
import Emitter from 'src/utils/EventEmitter';
import { mergeObjectWithContactArrayWithDedup } from 'src/utils/Tools/LodashTool';
import { DefaultShiftRequestsPagePagination, EmitterEventType, NativePath, NativeShiftRequestOptionSet, } from '../../../../shared';
import { useScheduleApi } from '../../../../hooks/api';
import TableFiltersRow from '../../../OptimizedTableView/OptionHeader/TableFiltersRow';
import TableOptionHeader from '../../../OptimizedTableView/OptionHeader/TableOptionHeader';
import { BlockHoverOptionButton } from '../../../OptimizedTableView/OptionHeader/TableOptionHeaderFromBlock';
import PaginationRow from '../../../OptimizedTableView/PaginationRow';
import TimeZoneSelector from '../../../TimeZoneSelector/TimeZoneSelector';
import './ShiftReleaseTable.scss';
import ShiftReleaseTableRow from './ShiftReleaseTableRow';
var ShiftReleaseTable = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var blockId = _a.blockId, defaultData = _a.defaultData, isRecordDetailModal = _a.isRecordDetailModal;
    var _o = useParams().pageId, pageId = _o === void 0 ? '' : _o;
    var _p = useState({
        statusFilter: 'pending',
    }), requestParams = _p[0], setRequestParams = _p[1];
    var requestParamsRef = useRef(requestParams);
    useEffect(function () {
        requestParamsRef.current = requestParams;
    }, [requestParams]);
    var useFetchShiftReleaseApprovalBlockQuery = useScheduleApi().useFetchShiftReleaseApprovalBlockQuery;
    var _q = useFetchShiftReleaseApprovalBlockQuery(__assign({ blockId: blockId }, requestParams)), data = _q.data, isLoading = _q.isLoading, isFetching = _q.isFetching;
    var returnedTableData = (_b = data === null || data === void 0 ? void 0 : data[0]) === null || _b === void 0 ? void 0 : _b.releaseRequests;
    var _r = useState(defaultData || returnedTableData), tableData = _r[0], setTableData = _r[1];
    var _s = useRecoilState(blockTimeZoneState(blockId !== null && blockId !== void 0 ? blockId : '')), blockTimeZone = _s[0], setBlockTimeZone = _s[1];
    var viewId = (tableData !== null && tableData !== void 0 ? tableData : {}).viewId;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (returnedTableData) {
            // !MARK: - only merge when pagination page is larger than 0 ( happen when user click on load more button)
            if (((_b = (_a = requestParamsRef.current) === null || _a === void 0 ? void 0 : _a.pagination) === null || _b === void 0 ? void 0 : _b.page) !== 0) {
                setTableData(function (prevState) {
                    return !prevState
                        ? returnedTableData
                        : __assign(__assign({}, prevState), { requests: uniqBy(prevState.requests.concat(returnedTableData.requests), 'shiftRid'), shiftCollection: mergeObjectWithContactArrayWithDedup(prevState.shiftCollection, returnedTableData.shiftCollection) });
                });
            }
            else {
                setTableData(returnedTableData);
            }
            if ((_d = (_c = returnedTableData.displaySettings) === null || _c === void 0 ? void 0 : _c.extra) === null || _d === void 0 ? void 0 : _d.timezone) {
                setBlockTimeZone((_f = (_e = returnedTableData.displaySettings) === null || _e === void 0 ? void 0 : _e.extra) === null || _f === void 0 ? void 0 : _f.timezone);
            }
            if (returnedTableData.requests.length <
                (((_h = (_g = requestParamsRef.current) === null || _g === void 0 ? void 0 : _g.pagination) === null || _h === void 0 ? void 0 : _h.size) || 0) &&
                returnedTableData.requests.length &&
                ((_k = (_j = requestParamsRef.current) === null || _j === void 0 ? void 0 : _j.pagination) === null || _k === void 0 ? void 0 : _k.page) === 0) {
                onLoadNextPage();
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnedTableData]);
    var refetchWithShiftCollectionUpdated = useCallback(function (collectionId) {
        if (collectionId === (tableData === null || tableData === void 0 ? void 0 : tableData.shiftCollection.id)) {
            setRequestParams(__assign(__assign({}, requestParamsRef.current), { pagination: {
                    page: 0,
                    size: Math.max(tableData.requests.length, DefaultShiftRequestsPagePagination.size),
                } }));
        }
    }, [tableData === null || tableData === void 0 ? void 0 : tableData.requests.length, tableData === null || tableData === void 0 ? void 0 : tableData.shiftCollection.id]);
    useEffect(function () {
        Emitter.on(EmitterEventType.REFETCH_COLLECTION, refetchWithShiftCollectionUpdated);
        return function () {
            Emitter.off(EmitterEventType.REFETCH_COLLECTION, refetchWithShiftCollectionUpdated);
        };
    });
    var parseRecordData = useCallback(function (newTableData) {
        var tempRecordData = [];
        var shiftInfos = {};
        if (!tableData) {
            return tempRecordData;
        }
        var dataToParse = newTableData !== null && newTableData !== void 0 ? newTableData : tableData;
        if ((dataToParse === null || dataToParse === void 0 ? void 0 : dataToParse.requests.length) === 0) {
            return tempRecordData;
        }
        var shiftCollection = dataToParse.shiftCollection;
        var shiftStartSchema = Object.values(shiftCollection.schemas).find(function (schema) { return (schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_START_AT; });
        var shiftEndSchema = Object.values(shiftCollection.schemas).find(function (schema) { return (schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_END_AT; });
        var shiftLocationSchema = Object.values(shiftCollection.schemas).find(function (schema) { return (schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_LOCATION_ID; });
        var shiftRoleSchema = Object.values(shiftCollection.schemas).find(function (schema) { return (schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_ROLE_IDS; });
        shiftCollection.recordOrders.forEach(function (recordId) {
            var _a, _b, _c;
            var shiftStart = (_a = _.find(shiftCollection.nativeFields, function (field) {
                return field.recordId === recordId && field.schemaId === (shiftStartSchema === null || shiftStartSchema === void 0 ? void 0 : shiftStartSchema.id);
            })) === null || _a === void 0 ? void 0 : _a.timestamp;
            var shiftEnd = (_b = _.find(shiftCollection.nativeFields, function (field) {
                return field.recordId === recordId && field.schemaId === (shiftEndSchema === null || shiftEndSchema === void 0 ? void 0 : shiftEndSchema.id);
            })) === null || _b === void 0 ? void 0 : _b.timestamp;
            var shiftLocationNative = _.find(shiftCollection.nativeFields, function (field) {
                return field.recordId === recordId && field.schemaId === (shiftLocationSchema === null || shiftLocationSchema === void 0 ? void 0 : shiftLocationSchema.id);
            });
            var shiftRolesNative = _.find(shiftCollection.nativeFields, function (field) {
                return field.recordId === recordId && field.schemaId === (shiftRoleSchema === null || shiftRoleSchema === void 0 ? void 0 : shiftRoleSchema.id);
            });
            var hasLocation = !!(shiftLocationNative === null || shiftLocationNative === void 0 ? void 0 : shiftLocationNative.text);
            var location = undefined;
            if (hasLocation) {
                location = {
                    label: shiftLocationNative.text,
                    color: shiftLocationNative.color,
                };
            }
            shiftInfos[recordId] = {
                recordId: recordId,
                startAt: shiftStart || '',
                endAt: shiftEnd || '',
                location: location,
                roles: ((_c = shiftRolesNative === null || shiftRolesNative === void 0 ? void 0 : shiftRolesNative.nativeObjects) === null || _c === void 0 ? void 0 : _c.map(function (role) { return ({
                    label: role.text || '',
                    color: role.color,
                }); })) || [],
            };
        });
        dataToParse.requests.forEach(function (request) {
            var data = {
                shiftRequest: request,
                shift: shiftInfos[request.shiftRid],
            };
            tempRecordData.push(data);
        });
        return tempRecordData;
    }, [tableData]);
    var _t = useState([]), recordData = _t[0], setRecordData = _t[1];
    //!MARK: - once table data updated, update record data
    useEffect(function () {
        if (tableData) {
            setRecordData(parseRecordData());
        }
    }, [parseRecordData, tableData]);
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var dataViewSettings = useMemo(function () {
        return returnedTableData === null || returnedTableData === void 0 ? void 0 : returnedTableData.displaySettings;
    }, [returnedTableData === null || returnedTableData === void 0 ? void 0 : returnedTableData.displaySettings]);
    var optionHeaderDisplaySettings = useMemo(function () {
        var filteredSettings = (requestParams === null || requestParams === void 0 ? void 0 : requestParams.displaySettings) || dataViewSettings;
        if (!filteredSettings || !tableData)
            return undefined;
        var tempSchemaOptions = filteredSettings.schemaOptions.filter(function (opt) {
            var schema = tableData.shiftCollection.schemas[opt.schemaId];
            if (!(schema === null || schema === void 0 ? void 0 : schema.nativePath) || NativeShiftRequestOptionSet.has(schema.nativePath)) {
                return true;
            }
        });
        return __assign(__assign({}, filteredSettings), { schemaOptions: tempSchemaOptions });
    }, [dataViewSettings, requestParams === null || requestParams === void 0 ? void 0 : requestParams.displaySettings, tableData]);
    var onOpenRecordDetailModal = useCallback(function (recordId) {
        openRecordDetail(recordId, blockTimeZone);
    }, [blockTimeZone, openRecordDetail]);
    var applyDisplaySettingsToView = useDisplaySettings().applyDisplaySettingsToView;
    var applyFilter = useCallback(function (filters, shouldSave, displaySettingsExtra) { return __awaiter(void 0, void 0, void 0, function () {
        var tempDisplaySettings, newDisplaySettings;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    tempDisplaySettings = ((_a = cloneDeep(requestParamsRef.current)) === null || _a === void 0 ? void 0 : _a.displaySettings) || dataViewSettings;
                    if (!viewId || !tempDisplaySettings)
                        return [2 /*return*/];
                    newDisplaySettings = __assign(__assign({}, tempDisplaySettings), { filters: filters, extra: __assign({ timezone: blockTimeZone }, displaySettingsExtra) });
                    setRequestParams(__assign(__assign({}, requestParamsRef.current), { displaySettings: newDisplaySettings, pagination: DefaultShiftRequestsPagePagination }));
                    // wait until data is loaded
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 0); })];
                case 1:
                    // wait until data is loaded
                    _b.sent();
                    if (!blockId) return [3 /*break*/, 3];
                    return [4 /*yield*/, applyDisplaySettingsToView(blockId, { filters: filters }, shouldSave, true, viewId)];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, blockId, blockTimeZone, dataViewSettings, viewId]);
    var handleApplySchemaOptions = useCallback(function (schemaOptions) { return __awaiter(void 0, void 0, void 0, function () {
        var newSettings;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!blockId || !viewId || !optionHeaderDisplaySettings)
                        return [2 /*return*/];
                    newSettings = produce(optionHeaderDisplaySettings, function (draft) {
                        draft.schemaOptions = schemaOptions;
                        draft.extra = __assign(__assign({}, optionHeaderDisplaySettings.extra), { timezone: blockTimeZone });
                    });
                    return [4 /*yield*/, applyDisplaySettingsToView(blockId, { schemaOptions: schemaOptions }, undefined, true, viewId)];
                case 1:
                    _a.sent();
                    setRequestParams(__assign(__assign({}, requestParamsRef.current), { displaySettings: newSettings }));
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, blockId, blockTimeZone, optionHeaderDisplaySettings, viewId]);
    var applyBlockTimeZone = useCallback(function (timeZone) { return __awaiter(void 0, void 0, void 0, function () {
        var newDisplaySettings;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setBlockTimeZone(timeZone);
                    if (!(blockId && viewId && optionHeaderDisplaySettings)) return [3 /*break*/, 2];
                    newDisplaySettings = __assign(__assign({}, optionHeaderDisplaySettings), { extra: __assign(__assign({}, optionHeaderDisplaySettings.extra), { timezone: timeZone }) });
                    return [4 /*yield*/, applyDisplaySettingsToView(blockId, newDisplaySettings, true, true, viewId)];
                case 1:
                    _a.sent();
                    if (newDisplaySettings.filters.length) {
                        //! Only need to refetch for timezone if there are filters
                        setRequestParams(__assign(__assign({}, requestParamsRef.current), { displaySettings: newDisplaySettings, pagination: DefaultShiftRequestsPagePagination }));
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, blockId, optionHeaderDisplaySettings, setBlockTimeZone, viewId]);
    var applyStatusFilter = useCallback(function (statusFilter) {
        setRequestParams(__assign(__assign({}, requestParamsRef.current), { statusFilter: statusFilter, pagination: DefaultShiftRequestsPagePagination }));
    }, []);
    var _u = useRecoilState(collectionTableSchemasState((_c = tableData === null || tableData === void 0 ? void 0 : tableData.shiftCollection.id) !== null && _c !== void 0 ? _c : '')), schemas = _u[0], setSchemas = _u[1];
    var shiftCollection = useMemo(function () {
        return tableData === null || tableData === void 0 ? void 0 : tableData.shiftCollection;
    }, [tableData === null || tableData === void 0 ? void 0 : tableData.shiftCollection]);
    //! Set schemas state for column visibility dropdown if shift schemas werent already set
    useEffect(function () {
        if ((shiftCollection === null || shiftCollection === void 0 ? void 0 : shiftCollection.id) && !schemas.length) {
            setSchemas(Object.values(shiftCollection === null || shiftCollection === void 0 ? void 0 : shiftCollection.schemas));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shiftCollection === null || shiftCollection === void 0 ? void 0 : shiftCollection.id]);
    var returnedDataRef = useRef(returnedTableData);
    useEffect(function () {
        returnedDataRef.current = returnedTableData;
    }, [returnedTableData]);
    var onLoadNextPage = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setRequestParams(function (prev) {
                var _a, _b, _c, _d;
                return (__assign(__assign({}, prev), { pagination: {
                        //!MARK: - increase page anyway when there's valid data back last time or when status filter is approved/rejected(they paginate by 7 days)
                        page: ((_b = (_a = prev === null || prev === void 0 ? void 0 : prev.pagination) === null || _a === void 0 ? void 0 : _a.page) !== null && _b !== void 0 ? _b : 0) +
                            (((_c = returnedDataRef.current) === null || _c === void 0 ? void 0 : _c.requests.length) || prev.statusFilter !== 'pending'
                                ? 1
                                : 0),
                        size: ((_d = prev === null || prev === void 0 ? void 0 : prev.pagination) === null || _d === void 0 ? void 0 : _d.size) || DefaultShiftRequestsPagePagination.size,
                    } }));
            });
            return [2 /*return*/];
        });
    }); }, []);
    var noRecordFound = useMemo(function () {
        var _a, _b, _c, _d, _e;
        return ((_b = (_a = requestParams === null || requestParams === void 0 ? void 0 : requestParams.pagination) === null || _a === void 0 ? void 0 : _a.page) !== null && _b !== void 0 ? _b : 0) > 0 &&
            !((_e = (_d = (_c = data === null || data === void 0 ? void 0 : data[0]) === null || _c === void 0 ? void 0 : _c.releaseRequests) === null || _d === void 0 ? void 0 : _d.requests) === null || _e === void 0 ? void 0 : _e.length);
    }, [data, (_d = requestParams === null || requestParams === void 0 ? void 0 : requestParams.pagination) === null || _d === void 0 ? void 0 : _d.page]);
    var paginationRow = useMemo(function () { return (_jsx(PaginationRow, { className: "ShiftRequestsPaginationRow", isLoading: isFetching, noRecordsFound: noRecordFound, onLoadMore: onLoadNextPage, recordType: "more shift release requests", loadMoreText: requestParams.statusFilter !== 'pending' ? 'Load 7 Days More' : undefined })); }, [isFetching, noRecordFound, onLoadNextPage, requestParams.statusFilter]);
    var canUpdateFilterOnPage = useRecoilValue(canUpdateFilterOnPageSelector(pageId));
    //! Virtual list
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var listRef = useRef(undefined);
    var rowHeights = useRef({});
    var _v = useWindowSize().windowHeight, windowHeight = _v === void 0 ? 500 : _v;
    var setRowHeight = useCallback(function (index, size) {
        var _a;
        listRef.current.resetAfterIndex(0);
        rowHeights.current = __assign(__assign({}, rowHeights.current), (_a = {}, _a[index] = size, _a));
    }, []);
    var getRowHeight = useCallback(function (index) {
        return rowHeights.current[index] || 80;
    }, []);
    /**
     * Main Table Related
     */
    return isLoading && !tableData ? (_jsxs("div", { className: "BlockTableWrapper", children: [blockId && _jsx(BlockHoverOptionButton, { blockId: blockId }), _jsx(Skeleton, { active: true })] })) : (_jsxs("span", { className: "ShiftRequestWrapper", children: [blockId && optionHeaderDisplaySettings && (_jsxs(_Fragment, { children: [_jsx(TableOptionHeader, { viewId: viewId !== null && viewId !== void 0 ? viewId : '', collectionId: (_e = shiftCollection === null || shiftCollection === void 0 ? void 0 : shiftCollection.id) !== null && _e !== void 0 ? _e : '', title: '', displaySettings: optionHeaderDisplaySettings, hoverOptionButton: _jsx(BlockHoverOptionButton, { blockId: blockId }), applySchemaOptions: handleApplySchemaOptions, extraOptions: !isRecordDetailModal && (_jsxs(_Fragment, { children: [_jsx("div", { className: "ShiftRequestStatusFilterHeader", children: ['pending', 'approved', 'rejected'].map(function (status) {
                                        return (_jsx("div", { className: 'ShiftRequestFilter' +
                                                (requestParams.statusFilter == status
                                                    ? ' selected'
                                                    : ''), onClick: function () { return applyStatusFilter(status); }, children: _.capitalize(status) }, status));
                                    }) }), _jsx(TimeZoneSelector, { timezoneValue: blockTimeZone, timezoneOnChange: applyBlockTimeZone, canEdit: true })] })) }), canUpdateFilterOnPage && (_jsx(TableFiltersRow, { filters: optionHeaderDisplaySettings.filters, blockProps: { blockId: blockId, collectionViewId: viewId }, applyFilter: applyFilter, collectionId: (_f = shiftCollection === null || shiftCollection === void 0 ? void 0 : shiftCollection.id) !== null && _f !== void 0 ? _f : '', canApplyFilter: canUpdateFilterOnPage && !isRecordDetailModal }))] })), _jsxs("div", { className: "BlockTableWrapper", children: [_jsxs("div", { className: "ShiftReleaseTable", children: [!!recordData.length &&
                                (((_h = (_g = requestParams.pagination) === null || _g === void 0 ? void 0 : _g.page) !== null && _h !== void 0 ? _h : 0) > 0 ||
                                    (!isFetching && ((_k = (_j = requestParams.pagination) === null || _j === void 0 ? void 0 : _j.page) !== null && _k !== void 0 ? _k : 0) === 0)) && (_jsx(VariableSizeList, { ref: listRef, itemCount: recordData.length, itemSize: getRowHeight, itemKey: function (index) { return "".concat(recordData[index].shift.recordId, "-").concat(index); }, height: Math.max(windowHeight - 200, 500), width: "100%", overscanCount: 5, children: function (_a) {
                                    var _b;
                                    var index = _a.index, style = _a.style;
                                    return (_jsx(ShiftReleaseTableRow, { blockId: blockId, recordData: recordData, index: index, style: style, statusFilter: requestParams.statusFilter, tableData: tableData, schemaOptions: (_b = optionHeaderDisplaySettings === null || optionHeaderDisplaySettings === void 0 ? void 0 : optionHeaderDisplaySettings.schemaOptions) !== null && _b !== void 0 ? _b : [], timeZone: blockId ? blockTimeZone : undefined, setRowHeight: setRowHeight, setTableData: setTableData, onOpenRecordDetailModal: onOpenRecordDetailModal }));
                                } })), !recordData.length && !isFetching && (_jsx("div", { className: "ShiftRequestRow", children: _jsx("div", { className: "ShiftRequestHeader", children: "No Requests" }) })), isFetching && !((_l = requestParams.pagination) === null || _l === void 0 ? void 0 : _l.page) && (_jsx(Skeleton, { className: "ShiftRequestSkeleton".concat(((_m = requestParams.pagination) === null || _m === void 0 ? void 0 : _m.page) ? ' mt-1.5' : ''), active: true, title: false, paragraph: { rows: 5, width: '100%' } }))] }), blockId && (recordData.length > 0 || requestParams.statusFilter !== 'pending') && (_jsx(_Fragment, { children: paginationRow }))] })] }));
};
export default React.memo(ShiftReleaseTable);
