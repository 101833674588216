var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from 'react-query';
import { useFetch } from './useFetch';
export var useInternalToolsApi = function () {
    var customFetch = useFetch().request;
    var useBackfillTranslations = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "internal_tools/backfill_translations",
            }, __assign({}, request));
        });
    };
    var useUnlockNeedsCoverage = function () {
        var adminKey = localStorage.getItem('needsAndCoverageAdminKey');
        var adminKeyParam = adminKey != null ? "?code=".concat(adminKey) : '';
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "internal_tools/unlock_account_needs_and_coverage".concat(adminKeyParam),
            }, __assign({}, request));
        });
    };
    return {
        useBackfillTranslations: useBackfillTranslations,
        useUnlockNeedsCoverage: useUnlockNeedsCoverage,
    };
};
