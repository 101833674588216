var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, message } from 'antd';
import React, { useState } from 'react';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
var AdminActionButton = function (_a) {
    var label = _a.label, onClick = _a.onClick;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), showSuccess = _c[0], setShowSuccess = _c[1];
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isLoading || showSuccess)
                        return [2 /*return*/];
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, onClick()];
                case 2:
                    _a.sent();
                    setShowSuccess(true);
                    message.success("".concat(label, " completed successfully!"));
                    setTimeout(function () {
                        setShowSuccess(false);
                    }, 2000);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    message.error("Failed to ".concat(label.toLowerCase()));
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // Will: Is there a reason this needs to be in a render function instead of just directly in the JSX below?
    var getContent = function () {
        if (isLoading) {
            return (_jsxs(_Fragment, { children: [_jsx(LoadingOutlined, { className: "mr-2" }), "Processing..."] }));
        }
        if (showSuccess) {
            return (_jsxs(_Fragment, { children: [_jsx(CheckCircleFilled, { className: "mr-2" }), "Succeeded"] }));
        }
        return label;
    };
    return (_jsx(Button, { type: "primary", className: "px-0", disabled: isLoading, onClick: handleClick, children: _jsx("div", { className: "rounded-lg text-white mb-2 text-center font-semibold min-w-[180px] h-[35px] \n                flex items-center justify-center transition-all duration-300 bg-black", children: getContent() }) }));
};
export default React.memo(AdminActionButton);
