import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import React from 'react';
import { CompliancePageState } from 'src/hooks/state/Compliance';
import OvertimeExclusionSettings from './OvertimeExclusionSettings';
var OvertimeShiftExclusionSection = function (_a) {
    var groupIndex = _a.groupIndex;
    var _b = CompliancePageState.useContainer(), overtimeGroups = _b.overtimeGroups, addOvertimeExclusion = _b.addOvertimeExclusion;
    var currentOvertimeGroupExclusionSetting = overtimeGroups[groupIndex].exclusions;
    return (_jsxs("div", { className: "mt-4", children: [currentOvertimeGroupExclusionSetting.length !== 0 && (_jsxs("div", { className: "flex font-medium text-[#636363]", children: [_jsx("div", { className: "w-[215px]", children: "Exclude" }), _jsx("div", { children: "Condition" })] })), currentOvertimeGroupExclusionSetting.map(function (item) {
                var _a;
                return (_jsx(OvertimeExclusionSettings, { setting: item, groupInedx: groupIndex }, (_a = item.id) !== null && _a !== void 0 ? _a : ''));
            }), _jsx(Button, { className: "AddSectionRuleBtn", onClick: function () { return addOvertimeExclusion(groupIndex); }, children: "Add Condition" })] }));
};
export default React.memo(OvertimeShiftExclusionSection);
