var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import '../FeedContainer.scss';
import { Popover } from 'antd';
import ActivityFeedState, { ActivityFeedActorTypes } from 'src/hooks/state/ActivityFeed';
import { useRecordDetail } from 'src/hooks/component';
import { startCase } from 'lodash';
import { useAccountApi } from '../../../../hooks/api';
import { AccountHistoryContentType } from '../../Interface';
import ActivityFeedEvents from './ActivityFeedEvents';
export var ActivityFeedEventNames = (_a = {},
    //! Events name that dont match the content type
    _a[AccountHistoryContentType.SHIFT_CLAIM] = 'SHIFT_CLAIMED',
    _a[AccountHistoryContentType.SHIFT_RELEASE] = 'SHIFT_RELEASED',
    _a[AccountHistoryContentType.RELEASE_REQUEST_APPROVED] = 'RELEASE_SHIFT_REQUEST_APPROVED',
    _a[AccountHistoryContentType.RELEASE_REQUEST_REJECTED] = 'RELEASE_SHIFT_REQUEST_REJECTED',
    _a[AccountHistoryContentType.AUTOMATION_CREATED] = 'AUTOMATION_SAVED',
    _a[AccountHistoryContentType.AUTOMATION_SAVED] = 'AUTOMATION_SAVED',
    _a[AccountHistoryContentType.EMAIL_SENT] = 'EMAIL_SENT',
    _a[AccountHistoryContentType.IN_APP_MESSAGE] = 'IN_APP_MESSAGE',
    _a[AccountHistoryContentType.SMS_SENT] = 'SMS_SENT',
    _a[AccountHistoryContentType.RECORD_CREATED] = 'RECORD_CREATED',
    _a[AccountHistoryContentType.RECORD_DELETED] = 'RECORD_DELETED',
    _a[AccountHistoryContentType.RECORD_UPDATED] = 'RECORD_UPDATED',
    _a[AccountHistoryContentType.BREAK_CREATED] = 'BREAK_CREATED',
    _a[AccountHistoryContentType.BREAK_UPDATED] = 'BREAK_UPDATED',
    _a[AccountHistoryContentType.CONTACT_CREATED] = 'CONTACT_CREATED',
    _a[AccountHistoryContentType.CONTACT_DELETED] = 'CONTACT_DELETED',
    _a[AccountHistoryContentType.CONTACT_UPDATED] = 'CONTACT_UPDATED',
    _a[AccountHistoryContentType.DOCUMENT_COMPLETED] = 'DOCUMENT_COMPLETED',
    _a[AccountHistoryContentType.DOCUMENT_CREATED] = 'DOCUMENT_CREATED',
    _a[AccountHistoryContentType.DOCUMENT_DELETED] = 'DOCUMENT_DELETED',
    _a[AccountHistoryContentType.DOCUMENT_UPDATED] = 'DOCUMENT_UPDATED',
    _a[AccountHistoryContentType.TASK_CREATED] = 'TASK_CREATED',
    _a[AccountHistoryContentType.TASK_UPDATED] = 'TASK_UPDATED',
    _a[AccountHistoryContentType.TASK_DELETED] = 'TASK_DELETED',
    _a[AccountHistoryContentType.LOCATION_CREATED] = 'LOCATION_CREATED',
    _a[AccountHistoryContentType.LOCATION_DELETED] = 'LOCATION_DELETED',
    _a[AccountHistoryContentType.LOCATION_UPDATED] = 'LOCATION_UPDATED',
    _a[AccountHistoryContentType.PAY_PERIOD_CREATED] = 'PAY_PERIOD_CREATED',
    _a[AccountHistoryContentType.PAY_PERIOD_UPDATED] = 'PAY_PERIOD_UPDATED',
    _a[AccountHistoryContentType.PAY_PERIOD_DELETED] = 'PAY_PERIOD_DELETED',
    _a[AccountHistoryContentType.PROJECT_ENDED] = 'PROJECT_ENDED',
    _a[AccountHistoryContentType.PROJECT_STARTED] = 'PROJECT_STARTED',
    _a[AccountHistoryContentType.ROLE_CREATED] = 'ROLE_CREATED',
    _a[AccountHistoryContentType.ROLE_DELETED] = 'ROLE_DELETED',
    _a[AccountHistoryContentType.ROLE_UPDATED] = 'ROLE_UPDATED',
    _a[AccountHistoryContentType.SCHEDULE_ACTION] = 'SCHEDULE_ACTION',
    _a[AccountHistoryContentType.SHIFT_BREAK_IN] = 'SHIFT_BREAK_IN',
    _a[AccountHistoryContentType.SHIFT_BREAK_OUT] = 'SHIFT_BREAK_OUT',
    _a[AccountHistoryContentType.SHIFT_CLOCK_IN] = 'SHIFT_CLOCK_IN',
    _a[AccountHistoryContentType.SHIFT_CLOCK_OUT] = 'SHIFT_CLOCK_OUT',
    _a[AccountHistoryContentType.SHIFT_UNSCHEDULED_CLOCK_IN] = 'SHIFT_UNSCHEDULED_CLOCK_IN',
    _a[AccountHistoryContentType.SHIFT_CREATED] = 'SHIFT_CREATED',
    _a[AccountHistoryContentType.SHIFT_UPDATED] = 'SHIFT_UPDATED',
    _a[AccountHistoryContentType.SHIFT_DELETED] = 'SHIFT_DELETED',
    _a[AccountHistoryContentType.SHIFT_REQUEST_APPROVED] = 'SHIFT_REQUEST_APPROVED',
    _a[AccountHistoryContentType.SHIFT_REQUEST_REJECTED] = 'SHIFT_REQUEST_REJECTED',
    _a[AccountHistoryContentType.SHIFT_RECOMMENDED] = 'SHIFT_RECOMMENDED',
    _a[AccountHistoryContentType.SHIFT_RECOMMENDATION_ACCEPTED] = 'SHIFT_RECOMMENDATION_ACCEPTED',
    _a[AccountHistoryContentType.SHIFT_RECOMMENDATION_REJECTED] = 'SHIFT_RECOMMENDATION_REJECTED',
    _a[AccountHistoryContentType.SHIFT_GROUP_CREATED] = 'SHIFT_GROUP_CREATED',
    _a[AccountHistoryContentType.SHIFT_GROUP_UPDATED] = 'SHIFT_GROUP_UPDATED',
    _a[AccountHistoryContentType.SHIFT_GROUP_DELETED] = 'SHIFT_GROUP_DELETED',
    _a[AccountHistoryContentType.SHIFT_GROUP_CLAIMED] = 'SHIFT_GROUP_CLAIMED',
    _a[AccountHistoryContentType.SHIFT_GROUP_REQUEST_APPROVED] = 'SHIFT_GROUP_REQUEST_APPROVED',
    _a[AccountHistoryContentType.SHIFT_GROUP_REQUEST_REJECTED] = 'SHIFT_GROUP_REQUEST_REJECTED',
    _a[AccountHistoryContentType.TIMEOFF_CREATED] = 'TIMEOFF_CREATED',
    _a[AccountHistoryContentType.TIMEOFF_DELETED] = 'TIMEOFF_DELETED',
    _a[AccountHistoryContentType.TIMEOFF_UPDATED] = 'TIMEOFF_UPDATED',
    _a[AccountHistoryContentType.USER_CREATED] = 'USER_CREATED',
    _a[AccountHistoryContentType.USER_DELETED] = 'USER_DELETED',
    _a[AccountHistoryContentType.USER_UPDATED] = 'USER_UPDATED',
    _a[AccountHistoryContentType.USER_PAY_UPDATED] = 'USER_PAY_UPDATED',
    _a[AccountHistoryContentType.TASK_COMPLETED] = 'TASK_COMPLETED',
    _a[AccountHistoryContentType.TASK_ASSIGNED] = 'TASK_ASSIGNED',
    _a[AccountHistoryContentType.TASK_TEMPLATE_CREATED] = 'TASK_TEMPLATE_CREATED',
    _a[AccountHistoryContentType.TASK_TEMPLATE_UPDATED] = 'TASK_TEMPLATE_UPDATED',
    _a[AccountHistoryContentType.TASK_TEMPLATE_DELETED] = 'TASK_TEMPLATE_DELETED',
    _a[AccountHistoryContentType.TASK_GROUP_TEMPLATE_CREATED] = 'TASK_GROUP_TEMPLATE_CREATED',
    _a[AccountHistoryContentType.TASK_GROUP_TEMPLATE_UPDATED] = 'TASK_GROUP_TEMPLATE_UPDATED',
    _a[AccountHistoryContentType.TASK_GROUP_TEMPLATE_DELETED] = 'TASK_GROUP_TEMPLATE_DELETED',
    _a[AccountHistoryContentType.TASK_GROUP_ASSIGNED] = 'TASK_GROUP_ASSIGNED',
    _a[AccountHistoryContentType.TASK_GROUP_COMPLETED] = 'TASK_GROUP_COMPLETED',
    _a[AccountHistoryContentType.TASK_RULE_CREATED] = 'TASK_RULE_CREATED',
    _a[AccountHistoryContentType.TASK_RULE_UPDATED] = 'TASK_RULE_UPDATED',
    _a[AccountHistoryContentType.TASK_RULE_DELETED] = 'TASK_RULE_DELETED',
    _a[AccountHistoryContentType.PLACEMENT_CREATED] = 'PLACEMENT_CREATED',
    _a[AccountHistoryContentType.PLACEMENT_UPDATED] = 'PLACEMENT_UPDATED',
    _a[AccountHistoryContentType.PLACEMENT_DELETED] = 'PLACEMENT_DELETED',
    _a[AccountHistoryContentType.COMMENT_ADDED] = 'COMMENT_ADDED',
    _a[AccountHistoryContentType.PERMISSION_UPDATED] = 'PERMISSION_UPDATED',
    _a[AccountHistoryContentType.BUTTON_CLICKED] = 'BUTTON_CLICKED',
    _a[AccountHistoryContentType.JOB_CREATED] = 'JOB_CREATED',
    _a[AccountHistoryContentType.JOB_DELETED] = 'JOB_DELETED',
    _a[AccountHistoryContentType.JOB_UPDATED] = 'JOB_UPDATED',
    _a[AccountHistoryContentType.SHIFT_WAGE_UPDATED] = 'SHIFT_WAGE_UPDATED',
    _a[AccountHistoryContentType.SHIFT_SPLIT] = 'SHIFT_SPLIT',
    _a);
var ActivityFeed = function (props) {
    var collapsed = props.collapsed, recordId = props.recordId, actorType = props.actorType, actorId = props.actorId;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var useGetAccountCollectionOptionsQuery = useAccountApi().useGetAccountCollectionOptionsQuery;
    var collections = useGetAccountCollectionOptionsQuery().data;
    var _a = ActivityFeedState.useContainer(), collectionFilters = _a.collectionFilters, actorFilters = _a.actorFilters, eventFilters = _a.eventFilters, filterCollections = _a.filterCollections, filterSources = _a.filterSources, filterEventTypes = _a.filterEventTypes;
    var getTitle = function (str) {
        return startCase(str.toLowerCase());
    };
    var renderSourceContent = useCallback(function () {
        return (_jsx("div", { className: "max-w-[740px] max-h-[70vh] overflow-y-auto", children: ActivityFeedActorTypes.map(function (type) {
                return (_jsxs("label", { className: 'filterGroup ' + (actorFilters.includes(type) ? 'checked' : ''), children: [_jsx("input", { type: "checkbox", checked: actorFilters.includes(type)
                                ? actorFilters.includes(type)
                                : false, onChange: function () { return filterSources(type); } }), getTitle(type)] }, type));
            }) }));
    }, [actorFilters, filterSources]);
    var renderCollectionsContent = useCallback(function () {
        return (_jsx("div", { className: "max-w-[740px] max-h-[70vh] overflow-y-auto", children: collections === null || collections === void 0 ? void 0 : collections.map(function (collection) {
                return collection.name ? (_jsxs("label", { className: 'filterGroup ' +
                        (collectionFilters.includes(collection.id) ? 'checked' : ''), children: [_jsx("input", { type: "checkbox", checked: collectionFilters.includes(collection.id)
                                ? collectionFilters.includes(collection.id)
                                : false, onChange: function () { return filterCollections(collection.id); } }), collection.name] }, collection.id)) : (_jsx("label", {}, collection.id));
            }) }));
    }, [collectionFilters, collections, filterCollections]);
    var sortedEventTypes = useMemo(function () { return Object.values(ActivityFeedEventNames).sort(); }, []);
    var renderEventsContent = useCallback(function () {
        return (_jsx("div", { className: "max-w-[740px] max-h-[70vh] overflow-y-auto", children: sortedEventTypes.map(function (type) {
                return (_jsxs("label", { className: 'filterGroup ' + (eventFilters.includes(type) ? 'checked' : ''), children: [_jsx("input", { type: "checkbox", checked: eventFilters.includes(type)
                                ? eventFilters.includes(type)
                                : false, onChange: function () { return filterEventTypes(type); } }), getTitle(type)] }, type));
            }) }));
    }, [eventFilters, filterEventTypes, sortedEventTypes]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "activityFilters", children: [(!actorType || actorType != 'AUTOMATION') && (_jsx(Popover, { placement: "bottomLeft", content: renderSourceContent, trigger: "click", children: _jsx("div", { className: "filterGroup bg-white", children: "All Sources" }) })), _jsx(Popover, { placement: "bottomLeft", content: renderCollectionsContent, trigger: "click", children: _jsx("div", { className: "filterGroup bg-white", children: "All Collections" }) }), _jsx(Popover, { placement: "bottomLeft", content: renderEventsContent, trigger: "click", children: _jsx("div", { className: "filterGroup bg-white", children: "All Events" }) })] }), _jsx(ActivityFeedEvents, { collapsed: collapsed, subjectId: recordId, actorType: actorType, actorIds: actorId ? [actorId] : [], onOpenRecordDetail: openRecordDetail })] }));
};
export default React.memo(ActivityFeed);
