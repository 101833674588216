var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { Empty, Skeleton } from 'antd';
import { useReverseCollectionApi } from 'src/hooks/api';
import { useRecordComponent } from 'src/hooks/component/useRecord';
import { useReverseCollectionComponent } from 'src/hooks/component/useReverseCollection';
import { collectionTableCanEditSchemasSelector, collectionTableCanEditSelector, collectionTableTypeSelector, tableDisplaySettingsState, tableCollectionResetPageState, tableSearchTextState, tableSelectedViewIdState, tableViewRecordOrdersState, tableVisibleColumnIdsSelector, nextPageOfTableState, tableCollectionCurrentSizeSelector, } from 'src/state';
import { hasTitleColumn } from 'src/utils/Collection';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import { CollectionTableType, CollectionType, DefaultReverseCollectionPagePagination, minColumnWidth, } from 'src/shared';
import { displaySettingsForCollectionTable } from 'src/services';
import { usePrevious } from 'src/hooks/component/usePrevious';
import { useCollectionComponent, useDisplaySettings } from 'src/hooks/component';
import { useQueryClient } from 'react-query';
import CollectionTable from '../CollectionTable';
import CollectionTableErrorBoundary from '../Error/CollectionTableErrorBoundary';
import { TITLE_COLUMN_ID } from '../Constants';
import AddNewReverseRecordButton from './AddNewReverseRecordButton';
import AddNewReverseColumnButton from './AddNewReverseColumnButton';
var ReverseCollectionTable = function (_a) {
    var _b;
    var title = _a.title, collectionId = _a.collectionId, referenceCollectionId = _a.referenceCollectionId, referenceRecordId = _a.referenceRecordId, schemaId = _a.schemaId, standardRelation = _a.standardRelation, reverseRelationId = _a.reverseRelationId, defaultDisplaySettings = _a.initialDisplaySettings, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading;
    var queryClient = useQueryClient();
    var initialDisplaySettings = useState(defaultDisplaySettings)[0];
    var viewId = useRecoilValue(tableSelectedViewIdState(reverseRelationId));
    var recordOrders = useRecoilValue(tableViewRecordOrdersState(viewId));
    var displaySettings = useRecoilValue(tableDisplaySettingsState(reverseRelationId));
    var searchText = useRecoilValue(tableSearchTextState(reverseRelationId));
    var setResetPage = useSetRecoilState(tableCollectionResetPageState(reverseRelationId)); //! Only set true on filters/search change
    var prevFilter = usePrevious(displaySettings.filters);
    var prevSortBy = usePrevious(displaySettings.sortBy);
    var prevSearch = usePrevious(searchText);
    var processTableCollectionIntoState = useCollectionComponent().processTableCollectionIntoState;
    var applyDisplaySettingsToView = useDisplaySettings().applyDisplaySettingsToView;
    var editRecordCell = useRecordComponent().editRecordCell;
    var useListReverseCollectionQuery = useReverseCollectionApi().useListReverseCollectionQuery;
    var fetchAndProcessReverseCollection = useReverseCollectionComponent().fetchAndProcessReverseCollection;
    var reverseCollectionNextPageIndex = useRecoilValue(nextPageOfTableState(reverseRelationId));
    var collectionSize = useRecoilValue(tableCollectionCurrentSizeSelector(reverseRelationId));
    var _c = useState(-1), prevCollectionSize = _c[0], setPrevCollectionSize = _c[1];
    var _d = useState(false), pageLoading = _d[0], setPageLoading = _d[1];
    var pageSize = useMemo(function () { return DefaultReverseCollectionPagePagination.size; }, []);
    var page = useMemo(function () { return Math.floor(collectionSize / pageSize); }, [collectionSize, pageSize]);
    var canLoadMore = useMemo(function () { return prevCollectionSize !== collectionSize; }, [collectionSize, prevCollectionSize]);
    var requestBody = useMemo(function () { return ({
        recordId: referenceRecordId,
        collectionId: collectionId,
        schemaId: schemaId,
        standardRelation: standardRelation,
        displaySettings: displaySettingsForCollectionTable(initialDisplaySettings, displaySettings, isLoading),
        search: searchText || undefined,
    }); }, [
        referenceRecordId,
        collectionId,
        schemaId,
        standardRelation,
        initialDisplaySettings,
        displaySettings,
        isLoading,
        searchText,
    ]);
    useEffect(function () {
        setPrevCollectionSize(-1);
    }, [(_b = requestBody.displaySettings) === null || _b === void 0 ? void 0 : _b.filters, requestBody.search]);
    var onLoadNextPage = useRecoilCallback(function () { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var pagination, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!pageLoading) return [3 /*break*/, 2];
                    setPrevCollectionSize(collectionSize);
                    setPageLoading(true);
                    pagination = {
                        page: reverseCollectionNextPageIndex !== null && reverseCollectionNextPageIndex !== void 0 ? reverseCollectionNextPageIndex : page,
                        size: pageSize,
                    };
                    request = __assign(__assign({}, requestBody), { pagination: pagination });
                    return [4 /*yield*/, fetchAndProcessReverseCollection(request)];
                case 1:
                    _a.sent();
                    setPageLoading(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }; }, [
        collectionSize,
        fetchAndProcessReverseCollection,
        page,
        pageLoading,
        pageSize,
        requestBody,
        reverseCollectionNextPageIndex,
    ]);
    var _e = useListReverseCollectionQuery({
        body: requestBody,
        options: {
            enabled: !!viewId && !!requestBody.displaySettings,
        },
    }), data = _e.data, isFetched = _e.isFetched, isFetching = _e.isFetching;
    useEffect(function () {
        if (data && !isFetching) {
            processTableCollectionIntoState(reverseRelationId, data).then(function () {
                setIsLoading(false);
                setResetPage(false);
            });
            var isTimeOffType = (data === null || data === void 0 ? void 0 : data.collectionInfo.type) === CollectionType.TIMEOFF;
            if (isTimeOffType) {
                queryClient.invalidateQueries({
                    queryKey: ['timeoffSummary'],
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isFetching, reverseRelationId]);
    //! Reset page on filter/search change
    useEffect(function () {
        if (!isEqual(prevFilter, displaySettings.filters) || !isEqual(prevSearch, searchText)) {
            setResetPage(true);
        }
    }, [displaySettings.filters, prevFilter, prevSearch, searchText, setResetPage]);
    //! Process record orders for view if not set & display settings is the same
    useEffect(function () {
        if (!recordOrders &&
            isEqual(prevFilter, displaySettings.filters) &&
            isEqual(prevSortBy, displaySettings.sortBy) &&
            data &&
            !isFetching) {
            processTableCollectionIntoState(reverseRelationId, data).then(function () {
                setIsLoading(false);
                setResetPage(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displaySettings.filters, displaySettings.sortBy, prevFilter, prevSortBy]);
    var visibleSchemaIds = useRecoilValue(tableVisibleColumnIdsSelector({ collectionId: collectionId, tableId: reverseRelationId }));
    var columnWidthsMap = useMemo(function () {
        var _a;
        return (_a = displaySettings.schemaOptions) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, option) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[option.schemaId] = option.width, _a)));
        }, {});
    }, [displaySettings.schemaOptions]);
    var canAddNewRecord = useRecoilValue(collectionTableCanEditSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var canAddNewColumn = useRecoilValue(collectionTableCanEditSchemasSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var onEndResize = useCallback(function (columnWidth) {
        var columnId = Object.keys(columnWidth)[0];
        var newWidth = columnWidth[columnId];
        var schemaOptionIndex = displaySettings.schemaOptions.findIndex(function (option) { return option.schemaId === columnId; });
        if (schemaOptionIndex !== -1) {
            var newSchemaOptions = cloneDeep(displaySettings.schemaOptions);
            newSchemaOptions[schemaOptionIndex].width = Math.max(minColumnWidth, newWidth);
            applyDisplaySettingsToView(reverseRelationId, {
                schemaOptions: newSchemaOptions,
            }, true, false);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displaySettings.schemaOptions, reverseRelationId]);
    var onEndDragAndDrop = useCallback(function (columnOrderIds) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            applyDisplaySettingsToView(reverseRelationId, {
                schemaOptions: sortBy(displaySettings.schemaOptions, function (s) {
                    return columnOrderIds.indexOf(s.schemaId);
                }),
            }, true, false);
            return [2 /*return*/];
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displaySettings.schemaOptions, reverseRelationId]);
    var hasExtraTitleColumn = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            var type = snapshot
                .getLoadable(collectionTableTypeSelector({
                type: CollectionTableType.TABLE,
                collectionId: collectionId,
            }))
                .valueMaybe();
            if (!type)
                return false;
            return hasTitleColumn(type);
        };
    }, [collectionId]);
    var onEditCell = useCallback(function (cellPos, newValues, timeZone) {
        editRecordCell(CollectionTableType.TABLE, cellPos, newValues, timeZone);
    }, [editRecordCell]);
    // For endless scroll in detail modal
    var handlePaginationScroll = useCallback(function (event) {
        event.stopPropagation();
        var atBottom = event.target.scrollTop > 0 &&
            event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <=
                100;
        if (!pageLoading && atBottom && canLoadMore) {
            onLoadNextPage();
        }
    }, [canLoadMore, onLoadNextPage, pageLoading]);
    if (!data && isFetched) {
        return _jsx(CollectionTableErrorBoundary, { title: title });
    }
    return isLoading ? (_jsx("div", { className: "BlockTableWrapper", children: _jsx(Skeleton, { active: true }) })) : (_jsx(_Fragment, { children: (recordOrders === null || recordOrders === void 0 ? void 0 : recordOrders.length) === 0 ? (_jsx(Empty, { description: false, image: Empty.PRESENTED_IMAGE_SIMPLE, className: "m-0 bg-white p-8 shadow-block", children: canAddNewRecord && (_jsx("div", { className: "flex justify-center", children: _jsx(AddNewReverseRecordButton, { collectionId: collectionId, referenceCollectionId: referenceCollectionId, referenceRecordId: referenceRecordId, schemaId: schemaId, isCreatingFromEmpty: true }) })) })) : (_jsx("div", { className: "BlockTableWrapper", onScroll: handlePaginationScroll, children: _jsx(CollectionTable, { reverseRelationId: reverseRelationId, collectionId: collectionId, recordOrders: recordOrders !== null && recordOrders !== void 0 ? recordOrders : [], schemaIds: hasExtraTitleColumn()
                    ? __spreadArray([TITLE_COLUMN_ID], visibleSchemaIds, true) : visibleSchemaIds, columnWidthsMap: columnWidthsMap, editCell: onEditCell, addNewColumnButton: canAddNewColumn ? (_jsx(AddNewReverseColumnButton, { collectionId: collectionId, reverseRelationId: reverseRelationId })) : undefined, hasSelectRecordColumn: canAddNewRecord, onEndResize: onEndResize, onEndDragAndDrop: onEndDragAndDrop }) })) }));
};
export default React.memo(ReverseCollectionTable);
