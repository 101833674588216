import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { BellOutlined, CalendarOutlined, ClockCircleOutlined, EditOutlined, MailOutlined, MessageOutlined, NotificationOutlined, } from '@ant-design/icons';
import { Tag } from 'antd';
import { OPTIONS_COLOR_MAP, ValueDataType } from '../shared';
import { getTagTextColor } from './Collection';
export var getAutomationValueType = function (type) {
    switch (type) {
        case ValueDataType.PLAIN_SELECTION:
        case ValueDataType.NATIVE_USER_ID:
        case ValueDataType.NATIVE_USER_IDS:
        case ValueDataType.NATIVE_TYPE_ID:
        case ValueDataType.NATIVE_LOCATION_ID:
        case ValueDataType.NATIVE_LOCATION_IDS:
        case ValueDataType.NATIVE_ACCESS_GROUP_IDS:
        case ValueDataType.NATIVE_ROLE_IDS:
        case ValueDataType.NATIVE_TIMEOFF_POLICY_ID:
        case ValueDataType.NATIVE_OVERTIME_POLICY_ID:
        case ValueDataType.NATIVE_BREAK_POLICY_ID:
        case ValueDataType.NATIVE_BREAK_RULE_ID:
        case ValueDataType.NATIVE_GEOFENCE_ID:
        case ValueDataType.NATIVE_GENERATED:
        case ValueDataType.GENERATED:
        case ValueDataType.CUSTOM:
        case ValueDataType.SHIFT:
        case ValueDataType.USER:
        case ValueDataType.LOCATION:
        case ValueDataType.ROLE:
        case ValueDataType.PAY_PERIOD:
        case ValueDataType.NATIVE_SELECTION:
        case ValueDataType.JOB:
        case ValueDataType.PLACEMENT:
            return 'selection';
        case ValueDataType.NUMBER:
        case ValueDataType.NATIVE_NUMBER:
        case ValueDataType.NATIVE_DURATION:
            return 'number';
        case ValueDataType.RATING:
            return 'rating';
        case ValueDataType.METRIC:
            return 'metric';
        case ValueDataType.CURRENCY:
            return 'currency';
        case ValueDataType.PHONE:
        case ValueDataType.NATIVE_PHONE:
            return 'phone';
        case ValueDataType.DATETIME:
        case ValueDataType.DATETIME_RANGE:
        case ValueDataType.NATIVE_DATETIME:
            return 'datetime';
        case ValueDataType.BOOLEAN:
        case ValueDataType.NATIVE_BOOL:
            return 'boolean';
        case ValueDataType.NATIVE_EMAIL:
        case ValueDataType.EMAIL:
        case ValueDataType.NATIVE_URL:
        case ValueDataType.URL:
        case ValueDataType.TEXT:
        default:
            return 'text';
    }
};
export var getTagColorAndIcon = function (groupName, optionName) {
    switch (groupName) {
        case 'Data Workflows':
            return {
                color: '#e8f2fc',
                icon: _jsx(EditOutlined, {}),
            };
        case 'Specific Time':
            return {
                color: 'yellow',
                icon: _jsx(ClockCircleOutlined, {}),
            };
        case 'Clock In/Clock Out':
        case 'Breaks':
        case 'Shift Request':
        case 'Shift Release':
        case 'Shift Group Request':
        case 'Documents':
        case 'Recommended Shifts':
        case 'Geofence':
        case 'User Actions':
        case 'Tasks':
            return {
                color: '#f5efec',
                icon: _jsx(ClockCircleOutlined, {}),
            };
        case 'Scheduling':
        case 'Recurring Interval':
            return {
                color: '#eaedfc',
                icon: _jsx(CalendarOutlined, {}),
            };
        case 'Notifications':
            switch (optionName) {
                case 'Send SMS':
                    return {
                        color: '#e5f4ef',
                        icon: _jsx(MessageOutlined, {}),
                    };
                case 'Send email':
                    return {
                        color: '#ffecc8',
                        icon: _jsx(MailOutlined, {}),
                    };
                case 'Send feed message':
                    return {
                        color: '#dad6f3',
                        icon: _jsx(BellOutlined, {}),
                    };
                default:
                    return {
                        color: '#ffecc8',
                        icon: _jsx(NotificationOutlined, {}),
                    };
            }
        case 'Update Data':
            return {
                color: '#e8f2fc',
                icon: _jsx(EditOutlined, {}),
            };
        case 'Geofence Actions':
            return {
                color: '#ccede2',
                icon: _jsx(ClockCircleOutlined, {}),
            };
        case 'Comments':
            return {
                color: OPTIONS_COLOR_MAP.Purple,
                icon: _jsx(EditOutlined, {}),
            };
        case 'Button':
            return {
                color: OPTIONS_COLOR_MAP.Purple,
                icon: _jsx(EditOutlined, {}),
            };
        default:
            return {
                color: '#fff',
                icon: _jsx(_Fragment, {}),
            };
    }
};
export var automationTags = function (categories) {
    var tags = categories.map(function (category, index) {
        var _a = getTagColorAndIcon(category), color = _a.color, icon = _a.icon;
        return (_jsx(Tag, { className: "AutomationTag", style: { color: getTagTextColor(color) }, color: color, children: icon }, "".concat(category, "-").concat(index)));
    });
    return _jsx(_Fragment, { children: tags });
};
