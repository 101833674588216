var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button, Select } from 'antd';
import { CompliancePageState } from 'src/hooks/state/Compliance';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { formFilterSaveObject } from 'src/components/Automations/util';
import { useRecoilValue } from 'recoil';
import BuilderBar from 'src/components/Automations/BuilderBar';
import { AutomationComponent, AutomationFilterType, } from 'src/components/Automations/Interface';
import { appSessionState } from 'src/state';
import { CollectionType } from 'src/shared';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { useAccountApi } from 'src/hooks/api';
import { OvertimeExclusionType } from '../Interface';
var OvertimeExclusionSettings = function (_a) {
    var _b;
    var setting = _a.setting, groupInedx = _a.groupInedx;
    var accountId = ((_b = useRecoilValue(appSessionState)) !== null && _b !== void 0 ? _b : {}).accountId;
    var _c = CompliancePageState.useContainer(), overtimeExclusionConditionSchemas = _c.overtimeExclusionConditionSchemas, updateOvertimeExclusion = _c.updateOvertimeExclusion, deleteOvertimeExclusion = _c.deleteOvertimeExclusion;
    var shiftCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.SHIFT));
    var useGetAccountCollectionOptionsQuery = useAccountApi().useGetAccountCollectionOptionsQuery;
    var allCollections = useGetAccountCollectionOptionsQuery().data;
    var conditionTypeUpdate = useCallback(function (type) {
        if (!setting.id) {
            return;
        }
        updateOvertimeExclusion(groupInedx, setting.id, __assign(__assign({}, setting), { type: type }));
    }, [groupInedx, setting, updateOvertimeExclusion]);
    var builderSendDataBack = useCallback(function (builderState) {
        if (!setting.id) {
            return;
        }
        var builderData = formFilterSaveObject(builderState);
        var conditionObj = {
            schemaId: builderData.schemaId,
            secondarySchemaId: builderData.secondarySchemaId,
            clauseValue: builderData.clauseValue,
            filterValue: builderData.filterValue,
            valueIds: builderData.valueIds,
            dynamicValue: builderData.dynamicValue,
            bespokeValue: builderData.bespokeValue,
        };
        var newCondition = __assign(__assign({}, setting), conditionObj);
        updateOvertimeExclusion(groupInedx, setting.id, newCondition);
    }, [groupInedx, setting, updateOvertimeExclusion]);
    if (!accountId || !setting.id)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: "OvertimeConditionBuilderWrapper", children: [_jsxs(Select, { className: "w-[225px]", dropdownMatchSelectWidth: false, value: setting.type, onChange: conditionTypeUpdate, children: [_jsx(Select.Option, { value: OvertimeExclusionType.PAY, children: "Pay" }), _jsx(Select.Option, { value: OvertimeExclusionType.BILL, children: "Bill" })] }), _jsx(BuilderBar, { accountId: accountId, component: AutomationComponent.FILTER, allCollections: allCollections, savedFilter: __assign(__assign({}, setting), { collectionId: shiftCollectionId, appliesTo: AutomationFilterType.RECORD }), baseCollectionId: shiftCollectionId, defaultFilterOptions: overtimeExclusionConditionSchemas, sendDataBack: builderSendDataBack }), _jsx(Button, { className: "RecordDeleteButton rounded-md p-1 hover:cursor-pointer hover:bg-gray-60 ml-2", icon: _jsx(DeleteOutlinedIcon, {}), onClick: function () {
                    if (setting.id) {
                        deleteOvertimeExclusion(groupInedx, setting.id);
                    }
                } })] }));
};
export default React.memo(OvertimeExclusionSettings);
