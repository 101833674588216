import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableSchemaButtonSchemaSettingSelector } from 'src/state';
import './ButtonCell.scss';
import { getButtonBorderColor } from 'src/utils/Collection';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { usePageApi } from 'src/hooks/api';
var DEFAULT_BUTTON_COLOR = '#FFFFFF';
var ButtonCell = function (_a) {
    var _b;
    var cellPos = _a.cellPos, canEdit = _a.canEdit, isModalCell = _a.isModalCell, openBulkButtonClickActionConfirmation = _a.openBulkButtonClickActionConfirmation;
    var buttonConfig = useRecoilValue(collectionTableSchemaButtonSchemaSettingSelector(cellPos));
    var name = buttonConfig === null || buttonConfig === void 0 ? void 0 : buttonConfig.title;
    var color = (_b = buttonConfig === null || buttonConfig === void 0 ? void 0 : buttonConfig.color) !== null && _b !== void 0 ? _b : DEFAULT_BUTTON_COLOR;
    var darkerColor = getButtonBorderColor(color);
    var _c = useState(false), isButtonClicked = _c[0], setIsButtonClicked = _c[1];
    var disabledButton = !canEdit || isButtonClicked;
    var useClickButtonCell = usePageApi().useClickButtonCell;
    var refetchCollection = useRefetchCollection();
    var clickButton = useCallback(function () {
        if (disabledButton)
            return;
        if (openBulkButtonClickActionConfirmation) {
            openBulkButtonClickActionConfirmation(cellPos);
        }
        else {
            setIsButtonClicked(true);
            var request = {
                recordIds: [cellPos.recordId],
                schemaId: cellPos.schemaId,
                collectionId: cellPos.collectionId,
            };
            useClickButtonCell.mutate(request, {
                onSuccess: function (resp) {
                    if (resp) {
                        if (isModalCell) {
                            // Make button unclickable for 5 seconds
                            setTimeout(function () {
                                setIsButtonClicked(false);
                            }, 5000);
                        }
                        else {
                            // Refetch collection after button click only for table cells, which will rerender cell and make it clickable again
                            var collectionId_1 = cellPos.collectionId;
                            setTimeout(function () {
                                refetchCollection(collectionId_1);
                            }, 2000);
                        }
                    }
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canEdit, cellPos, disabledButton, openBulkButtonClickActionConfirmation, isModalCell]);
    if (!buttonConfig || !buttonConfig.title) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: "ButtonCellWrapper", children: _jsx("div", { className: "ButtonCell ".concat(!disabledButton ? 'cursor-pointer' : 'cursor-not-allowed'), style: {
                borderColor: !disabledButton ? darkerColor : '#E9E9EC',
            }, onClick: clickButton, children: _jsx("div", { className: "ButtonContent", style: {
                    backgroundColor: !disabledButton ? color : '#E9E9EC',
                }, children: name }) }) }));
};
export default React.memo(ButtonCell);
