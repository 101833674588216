import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import { requiredRules } from 'src/components/ImportCsvModal/Constant';
import { usePageApi } from 'src/hooks/api';
import { getNativeType } from 'src/utils/Collection';
var Option = Select.Option;
var lookUpTypeOptions = ['Name', 'Other schema'];
var SchemaMappingSelector = function (_a) {
    var ipSchema = _a.ipSchema, schemas = _a.schemas, isLoading = _a.isLoading;
    var useGetCollectionSchemas = usePageApi().useGetCollectionSchemas;
    var _b = useState(), refCollectionId = _b[0], setRefCollectionId = _b[1];
    var _c = useState(), lookUpType = _c[0], setLookUpType = _c[1];
    var lookUpSchemaOptions = useGetCollectionSchemas({
        body: { collectionId: refCollectionId },
        options: { enabled: !!refCollectionId && lookUpType === 'Other schema' },
    }).data;
    var lookUpTypeOnChange = useCallback(function (value) {
        setLookUpType(value);
    }, []);
    var refCollectionSchemaSelector = useCallback(function () {
        return (_jsxs("div", { className: "flex gap-1 ml-1 items-baseline", children: [_jsx(Select, { onChange: lookUpTypeOnChange, className: "lookupTypeSelector", children: lookUpTypeOptions.map(function (option) {
                        return (_jsx(Option, { value: option, children: option }, option));
                    }) }), lookUpType === 'Other schema' && lookUpSchemaOptions && (_jsx(Form.Item, { name: [ipSchema.displayName, 'lookupSchemaId'], rules: requiredRules, children: _jsx(Select, { dropdownMatchSelectWidth: false, children: lookUpSchemaOptions.map(function (option) {
                            return (_jsx(Option, { value: option.id, children: option.name }, option.id));
                        }) }) }))] }));
    }, [ipSchema.displayName, lookUpSchemaOptions, lookUpType, lookUpTypeOnChange]);
    var trimNativeType = useCallback(function (schema) {
        if (schema.inputMode === 'native') {
            return getNativeType(schema.type);
        }
        return schema.type;
    }, []);
    var onSchemaChange = useCallback(function (schemaId) {
        var selectedSchema = schemas.find(function (schema) { return schema.id === schemaId; });
        if (selectedSchema) {
            if (selectedSchema.refCollectionId) {
                setRefCollectionId(selectedSchema.refCollectionId);
            }
        }
        else {
            setRefCollectionId(undefined);
        }
    }, [schemas]);
    return (_jsxs("div", { className: "flex", children: [_jsx(Form.Item, { name: [ipSchema.displayName, 'schemaId'], colon: false, label: _jsxs("div", { children: [_jsx("span", { children: ipSchema.displayName }), _jsx("span", { className: "IntegrationSchemaType", children: ipSchema.type })] }), initialValue: ipSchema === null || ipSchema === void 0 ? void 0 : ipSchema.ziraSchema, children: _jsx(Select, { disabled: isLoading, className: "max-w-full", onChange: onSchemaChange, showSearch: true, optionFilterProp: "label", children: schemas.map(function (schema) { return (_jsxs(Option, { value: schema.id, label: schema.name, children: [_jsx("span", { children: schema.name }), _jsx("span", { className: "SchemaMappingOption", children: trimNativeType(schema) })] }, schema.id)); }) }) }, ipSchema.displayName), refCollectionId && refCollectionSchemaSelector()] }));
};
export default React.memo(SchemaMappingSelector);
