var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import ActivityFeed from 'src/components/CenterPanel/Feed/ActivityFeed/ActivityFeed';
import { useTasksApi } from 'src/hooks/api';
import { DEFAULT_TASK_RULE_INFO, NEW_TASK_RULE_ID } from '../../constant';
import TaskTemplateDragCard from '../../shared/TaskTemplateDragCard';
import { isTaskSettingMobilePreviewVisibleState, selectedTaskRuleIdState, isTaskSettingActivityFeedVisibleState, taskRuleDetailState, } from '../../atom';
import { TaskRuleAssignedTaskType, } from '../../interface';
import { taskRuleSelector } from '../../selector';
import TaskSaveWarningModal from '../../shared/TaskSaveWarningModal';
import TaskSelectionDropdown from '../../shared/TaskSelectionDropdown';
import '../../TaskGroupDetail/TaskGroupDetailContent.scss';
import TaskCollapsePanel from '../../shared/TaskCollapsePanel';
import { generateRandomFourDigitNumber } from '../../util';
import TaskMobilePreview from '../../shared/TaskMobilePreview';
import TaskSaveOptionsModal from '../../shared/TaskSaveOptionsModal';
import TaskGroupDragCard from './TaskGroupDragCard';
import TaskRuleConditions from './TaskRuleConditions';
var TaskRuleDetailContent = function () {
    var _a, _b;
    var _c = useRecoilState(selectedTaskRuleIdState), selectedTaskRuleId = _c[0], setSelectedTaskRuleId = _c[1];
    var taskRuleInfo = useRecoilValue(taskRuleSelector(selectedTaskRuleId !== null && selectedTaskRuleId !== void 0 ? selectedTaskRuleId : ''));
    var isTaskSettingMobilePreviewVisible = useRecoilValue(isTaskSettingMobilePreviewVisibleState);
    var isTaskSettingActivityFeedVisible = useRecoilValue(isTaskSettingActivityFeedVisibleState);
    var _d = useState(false), taskSaveWarningModalVisible = _d[0], setTaskSaveWarningModalVisible = _d[1];
    var _e = useState(false), taskSaveOptionsModalVisible = _e[0], setTaskSaveOptionsModalVisible = _e[1];
    var _f = useRecoilState(taskRuleDetailState), ruleDetail = _f[0], setRuleDetail = _f[1];
    var _g = useTasksApi(), useCreateTaskRule = _g.useCreateTaskRule, useUpdateTaskRule = _g.useUpdateTaskRule, refetchTaskRules = _g.refetchTaskRules;
    var isCreatingNewTaskRule = selectedTaskRuleId === NEW_TASK_RULE_ID;
    useEffect(function () {
        if (isCreatingNewTaskRule) {
            setRuleDetail(__assign(__assign({}, DEFAULT_TASK_RULE_INFO), { displayId: "task.rule.".concat(generateRandomFourDigitNumber()) }));
        }
        else if (taskRuleInfo) {
            setRuleDetail(taskRuleInfo);
        }
        return function () {
            setRuleDetail(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreatingNewTaskRule, taskRuleInfo]);
    var updateRule = useCallback(function (rule) {
        setRuleDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), rule);
            }
        });
    }, [setRuleDetail]);
    var updateConditionData = useCallback(function (collectionId, conditionData) {
        setRuleDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { collectionId: collectionId, conditionData: conditionData });
            }
        });
    }, [setRuleDetail]);
    var addTaskIntoRule = useCallback(function (task) {
        setRuleDetail(function (prev) {
            if (prev) {
                var tasks = cloneDeep(prev.tasks);
                tasks.push(task);
                return __assign(__assign({}, prev), { tasks: tasks });
            }
        });
    }, [setRuleDetail]);
    var onToggleCountCompletion = useCallback(function (taskId, isPreRequirement) {
        setRuleDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { tasks: prev.tasks.map(function (task) {
                        return task.id === taskId ? __assign(__assign({}, task), { isPreRequirement: isPreRequirement }) : task;
                    }) });
            }
            else {
                return prev;
            }
        });
    }, [setRuleDetail]);
    var removeTaskInRule = useCallback(function (taskIndex) {
        setRuleDetail(function (prev) {
            if (prev) {
                var tasks = cloneDeep(prev.tasks);
                tasks.splice(taskIndex, 1);
                return __assign(__assign({}, prev), { tasks: tasks });
            }
        });
    }, [setRuleDetail]);
    var onDragEnd = useCallback(function (_a) {
        var _b;
        var source = _a.source, destination = _a.destination;
        if (!destination || !ruleDetail)
            return;
        if (source.index === destination.index)
            return;
        var newTaskOrder = __spreadArray([], ((_b = ruleDetail.tasks) !== null && _b !== void 0 ? _b : []), true);
        var removed = newTaskOrder.splice(source.index, 1)[0];
        newTaskOrder.splice(destination.index, 0, removed);
        setRuleDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { tasks: newTaskOrder });
            }
        });
    }, [ruleDetail, setRuleDetail]);
    var renderAssignedTasksList = useMemo(function () { return (_jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Droppable, { droppableId: "TaskListDroppable", children: function (provided) { return (_jsxs("div", __assign({ className: "TaskListDroppable flex w-full flex-col gap-4 pb-4" }, provided.droppableProps, { ref: provided.innerRef, children: [ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.tasks.map(function (_a, index) {
                        var id = _a.id, type = _a.type, isPreRequirement = _a.isPreRequirement;
                        return (_jsx(Draggable, { draggableId: id, index: index, children: function (provided) {
                                if (type === TaskRuleAssignedTaskType.TEMPLATE) {
                                    return (_jsx(TaskTemplateDragCard, { draggableProvided: provided, taskId: id, isPreRequirement: isPreRequirement, index: index, onRemoveTask: removeTaskInRule, onToggleCountCompletion: onToggleCountCompletion }, id));
                                }
                                return (_jsx(TaskGroupDragCard, { draggableProvided: provided, taskGroupId: id, isPreRequirement: isPreRequirement, index: index, onRemoveTaskGroup: removeTaskInRule, onToggleCountCompletion: onToggleCountCompletion }, id));
                            } }, id));
                    }), provided.placeholder] }))); } }) })); }, [onDragEnd, onToggleCountCompletion, removeTaskInRule, ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.tasks]);
    var warningModalContent = useMemo(function () {
        var content = [];
        if (!(ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.name.trim())) {
            content.push('Name');
        }
        if (!(ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.displayId.trim())) {
            content.push('Rule ID');
        }
        if (!(ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.collectionId)) {
            content.push('Collection');
        }
        if ((ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.tasks.length) === 0) {
            content.push('Assign Tasks');
        }
        return content;
    }, [
        ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.tasks.length,
        ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.collectionId,
        ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.displayId,
        ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.name,
    ]);
    var onCancel = useCallback(function () {
        setSelectedTaskRuleId(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onSave = useCallback(function (saveOptions) { return __awaiter(void 0, void 0, void 0, function () {
        var requestBody, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!ruleDetail)
                        return [2 /*return*/];
                    requestBody = __assign(__assign({}, ruleDetail), { id: undefined, lastEdited: undefined });
                    if (!isCreatingNewTaskRule) return [3 /*break*/, 2];
                    return [4 /*yield*/, useCreateTaskRule.mutateAsync(__assign(__assign({}, requestBody), { saveOptions: saveOptions }), {
                            onSuccess: function (resp) {
                                if (resp === null || resp === void 0 ? void 0 : resp.taskRule) {
                                    setSelectedTaskRuleId(resp.taskRule.id);
                                }
                            },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    if (!saveOptions) return [3 /*break*/, 4];
                    request = {
                        ruleId: selectedTaskRuleId,
                        body: __assign(__assign({}, requestBody), { saveOptions: saveOptions }),
                    };
                    return [4 /*yield*/, useUpdateTaskRule.mutateAsync(request)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    refetchTaskRules();
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCreatingNewTaskRule, ruleDetail, selectedTaskRuleId, setSelectedTaskRuleId]);
    var contentFooter = useMemo(function () {
        return (_jsxs("div", { className: "TaskDetailMainContentFooter", children: [_jsx(Button, { className: "CancelBtn", onClick: onCancel, children: "Cancel" }), _jsx(Button, { className: "SaveBtn", onClick: function () {
                        if (warningModalContent.length) {
                            setTaskSaveWarningModalVisible(true);
                            return;
                        }
                        setTaskSaveOptionsModalVisible(true);
                    }, children: "Save" })] }));
    }, [onCancel, warningModalContent.length]);
    var _h = useState(['1', '2', '3']), activeSteps = _h[0], setActiveSteps = _h[1];
    var panelRefOne = useRef(null);
    var panelRefTwo = useRef(null);
    var panelRefThree = useRef(null);
    var scrollRef = useRef(null);
    useEffect(function () {
        setActiveSteps(['1', '2', '3']);
    }, [selectedTaskRuleId]);
    var goToStep = useCallback(function (step, fromStep) {
        var _a, _b;
        setActiveSteps(function (prev) { return prev.filter(function (s) { return s !== fromStep; }).concat(step); });
        var panelHeaderElement = (_b = (_a = (step === '1' ? panelRefOne : step === '2' ? panelRefTwo : panelRefThree).current) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement;
        if (panelHeaderElement) {
            setTimeout(function () {
                var _a;
                return (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                    behavior: 'smooth',
                    top: panelHeaderElement.offsetTop - 128,
                });
            }, 250);
        }
    }, []);
    return (_jsx(_Fragment, { children: ruleDetail && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "TaskDetailContainer", children: [_jsxs("div", { className: "TaskDetailMainContentWrapper", children: [_jsxs("div", { className: "TaskDetailMainContent", ref: scrollRef, children: [_jsx("div", { className: "flex items-center w-full justify-between", children: _jsxs("div", { className: "w-1/2", children: [_jsx(Input, { className: "TaskNameInput", bordered: false, autoFocus: true, placeholder: "Enter Rule Name Here", value: (_a = ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.name) !== null && _a !== void 0 ? _a : '', onChange: function (e) { return updateRule({ name: e.target.value }); } }), _jsx("hr", { className: "TaskContentDivider" })] }) }), _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel", children: "Rule ID*" }), _jsx(Input.TextArea, { className: "TaskDescriptionInput", value: (_b = ruleDetail === null || ruleDetail === void 0 ? void 0 : ruleDetail.displayId) !== null && _b !== void 0 ? _b : '', onChange: function (e) { return updateRule({ displayId: e.target.value }); }, placeholder: "Enter Rule ID Here" })] }), _jsxs("div", { className: "flex flex-col gap-[24px]", children: [_jsxs(TaskCollapsePanel, { ref: panelRefOne, step: "1", activeSteps: activeSteps, title: "Conditions", subtitle: "Choose attributes to apply to set of tasks", setActiveSteps: setActiveSteps, children: [_jsx(TaskRuleConditions, { ruleId: ruleDetail.id, collectionId: ruleDetail.collectionId, conditionData: ruleDetail.conditionData, onConditionChange: updateConditionData }), _jsx("div", { className: "flex justify-end pt-[16px]", children: _jsx(Button, { className: "CancelBtn", onClick: function () {
                                                                    goToStep('2', '1');
                                                                }, children: "Next Step" }) })] }), _jsxs(TaskCollapsePanel, { ref: panelRefTwo, step: "2", activeSteps: activeSteps, title: "Assign Tasks", subtitle: "Choose what tasks the rule should apply to", setActiveSteps: setActiveSteps, children: [renderAssignedTasksList, _jsx(TaskSelectionDropdown, { onAddTask: function (task) {
                                                                return addTaskIntoRule({
                                                                    id: task.id,
                                                                    type: TaskRuleAssignedTaskType.TEMPLATE,
                                                                    isPreRequirement: true,
                                                                });
                                                            }, onAddTaskGroup: function (taskGroup) {
                                                                return addTaskIntoRule({
                                                                    id: taskGroup.id,
                                                                    type: TaskRuleAssignedTaskType.GROUP,
                                                                    isPreRequirement: true,
                                                                });
                                                            }, selectedTasks: ruleDetail.tasks
                                                                .filter(function (task) {
                                                                return task.type ===
                                                                    TaskRuleAssignedTaskType.TEMPLATE;
                                                            })
                                                                .map(function (task) { return task.id; }), selectedTaskGroups: ruleDetail.tasks
                                                                .filter(function (task) {
                                                                return task.type === TaskRuleAssignedTaskType.GROUP;
                                                            })
                                                                .map(function (task) { return task.id; }), children: _jsxs("div", { className: "h-[32px] cursor-pointer pl-2 pr-3 py-1.5 bg-white rounded-lg border border-black border-opacity-10 justify-center items-center gap-2 inline-flex", children: [_jsx("div", { className: "relative text-xl", children: "+" }), _jsx("div", { className: "text-black text-sm font-semibold", children: "Add Task" })] }) }), _jsx("div", { className: "flex justify-end pt-[16px] space-x-[8px]", children: _jsx(Button, { className: "CancelBtn", onClick: function () {
                                                                    goToStep('1', '2');
                                                                }, children: "Previous Step" }) })] })] })] }), contentFooter] }), isTaskSettingMobilePreviewVisible && (_jsx(TaskMobilePreview, { ruleInfo: ruleDetail })), ruleDetail.id !== NEW_TASK_RULE_ID && isTaskSettingActivityFeedVisible && (_jsx("div", { className: "activityFeedWrapper min-w-[420px] bg-gray-40", children: _jsx(ActivityFeed, { collapsed: false, recordId: ruleDetail.id }) }))] }), taskSaveWarningModalVisible && (_jsx(TaskSaveWarningModal, { onClose: function () { return setTaskSaveWarningModalVisible(false); }, type: 'task rule', content: warningModalContent })), taskSaveOptionsModalVisible && (_jsx(TaskSaveOptionsModal, { onClose: function () { return setTaskSaveOptionsModalVisible(false); }, onSave: function (saveOptions) { return onSave(saveOptions); }, type: "rule", saveType: isCreatingNewTaskRule ? 'create' : 'update' }))] })) }));
};
export default React.memo(TaskRuleDetailContent);
