export var AutomationComponent;
(function (AutomationComponent) {
    AutomationComponent["TRIGGER"] = "trigger";
    AutomationComponent["FILTER"] = "filter";
    AutomationComponent["ACTION"] = "action";
})(AutomationComponent || (AutomationComponent = {}));
export var TriggerType;
(function (TriggerType) {
    TriggerType["SCHEDULED_TIME"] = "SCHEDULED_TIME";
    TriggerType["OBJECT_UPDATED"] = "DATA_UPDATED";
    TriggerType["OBJECT_CREATED"] = "DATA_CREATED";
    TriggerType["OBJECT_DELETED"] = "DATA_DELETED";
    TriggerType["BUTTON_CLICKED"] = "BUTTON_CLICKED";
    TriggerType["SCHEDULED_SHIFT_START"] = "SCHEDULED_SHIFT_START";
    TriggerType["SCHEDULED_SHIFT_END"] = "SCHEDULED_SHIFT_END";
    TriggerType["CLOCK_IN"] = "CLOCK_IN";
    TriggerType["CLOCK_OUT"] = "CLOCK_OUT";
    TriggerType["BREAK_START"] = "BREAK_START";
    TriggerType["BREAK_END"] = "BREAK_END";
    TriggerType["SCHEDULED_BREAK_START"] = "SCHEDULED_BREAK_START";
    TriggerType["SCHEDULED_BREAK_END"] = "SCHEDULED_BREAK_END";
    TriggerType["SCHEDULE_PUBLISHED"] = "SCHEDULE_PUBLISHED";
    TriggerType["SHIFT_CLAIMED"] = "SHIFT_CLAIMED";
    TriggerType["SHIFT_GROUP_CLAIMED"] = "SHIFT_GROUP_CLAIMED";
    TriggerType["SHIFT_RECOMMENDED"] = "SHIFT_RECOMMENDED";
    TriggerType["UNSCHEDULED_CLOCK_IN"] = "UNSCHEDULED_CLOCK_IN";
    TriggerType["CLAIM_APPROVED"] = "CLAIM_APPROVED";
    TriggerType["CLAIM_REJECTED"] = "CLAIM_REJECTED";
    TriggerType["SHIFT_GROUP_CLAIM_APPROVED"] = "SHIFT_GROUP_CLAIM_APPROVED";
    TriggerType["SHIFT_GROUP_CLAIM_REJECTED"] = "SHIFT_GROUP_CLAIM_REJECTED";
    TriggerType["ENTERED_GEOFENCE"] = "ENTERED_GEOFENCE";
    TriggerType["LEFT_GEOFENCE"] = "LEFT_GEOFENCE";
    TriggerType["AT_SCHEDULED_TIME"] = "AT_SCHEDULED_TIME";
    TriggerType["DOCUMENT_COMPLETED"] = "DOCUMENT_COMPLETED";
    TriggerType["TASK_COMPLETED"] = "TASK_COMPLETED";
    TriggerType["TASK_ASSIGNED"] = "TASK_ASSIGNED";
    TriggerType["TASK_GROUP_ASSIGNED"] = "TASK_GROUP_ASSIGNED";
    TriggerType["TASK_GROUP_COMPLETED"] = "TASK_GROUP_COMPLETED";
    TriggerType["RECURRING"] = "RECURRING";
    TriggerType["COMMENT_ADDED"] = "COMMENT_ADDED";
})(TriggerType || (TriggerType = {}));
export var OffsetType;
(function (OffsetType) {
    OffsetType["MINUTES"] = "MINUTES";
    OffsetType["DAYS"] = "DAYS";
    OffsetType["HOURS"] = "HOURS";
})(OffsetType || (OffsetType = {}));
export var ActionType;
(function (ActionType) {
    ActionType["SEND_EMAIL"] = "SEND_EMAIL";
    ActionType["SEND_SMS"] = "SEND_SMS";
    ActionType["SEND_IN_APP"] = "SEND_IN_APP";
    ActionType["SEND_REPORT"] = "SEND_REPORT";
    ActionType["MODIFY_OBJECT"] = "EDIT_DATA";
    ActionType["CREATE_RECORD"] = "CREATE_RECORD";
    ActionType["DELETE_RECORD"] = "DELETE_RECORD";
    ActionType["CLOCK_IN"] = "CLOCK_IN";
    ActionType["CLOCK_OUT"] = "CLOCK_OUT";
    ActionType["START_BREAK"] = "START_BREAK";
    ActionType["END_BREAK"] = "END_BREAK";
    ActionType["START_NEXT_SHIFT"] = "START_NEXT_SHIFT";
    ActionType["ASSIGN_TASK"] = "ASSIGN_TASK";
    ActionType["ASSIGN_TASK_GROUP"] = "ASSIGN_TASK_GROUP";
    ActionType["SPLIT_SHIFT"] = "SPLIT_SHIFT";
})(ActionType || (ActionType = {}));
export var MessageActionTypeSet = new Set([
    ActionType.SEND_EMAIL,
    ActionType.SEND_SMS,
    ActionType.SEND_IN_APP,
    ActionType.SEND_REPORT,
]);
export var TargetType;
(function (TargetType) {
    TargetType["AUTOMATION_DETERMINED"] = "AUTOMATION_DETERMINED";
    TargetType["FILTERED_SET"] = "FILTERED_SET";
    TargetType["HARDCODED"] = "HARDCODED";
    TargetType["QUALIFIED_USERS"] = "QUALIFIED_USERS";
})(TargetType || (TargetType = {}));
export var MessageAttachmentType;
(function (MessageAttachmentType) {
    MessageAttachmentType["FORM"] = "FORM";
})(MessageAttachmentType || (MessageAttachmentType = {}));
export var AutomationFilterType;
(function (AutomationFilterType) {
    AutomationFilterType["RECORD"] = "RECORD";
    AutomationFilterType["ACTOR"] = "ACTOR";
})(AutomationFilterType || (AutomationFilterType = {}));
export var ReportPayPeriodType;
(function (ReportPayPeriodType) {
    ReportPayPeriodType["CURRENT_PAY_PERIOD"] = "CURRENT_PAY_PERIOD";
    ReportPayPeriodType["LAST_PAY_PERIOD"] = "LAST_PAY_PERIOD";
})(ReportPayPeriodType || (ReportPayPeriodType = {}));
export var AutomationReportType;
(function (AutomationReportType) {
    AutomationReportType["FROM_REPORT_FORMAT"] = "FROM_REPORT_FORMAT";
    AutomationReportType["FROM_TEMPLATE"] = "FROM_TEMPLATE";
})(AutomationReportType || (AutomationReportType = {}));
export var AutomationTriggerRecurringType;
(function (AutomationTriggerRecurringType) {
    AutomationTriggerRecurringType["DAILY"] = "DAILY";
    AutomationTriggerRecurringType["WEEKLY"] = "WEEKLY";
    AutomationTriggerRecurringType["MONTHLY"] = "MONTHLY";
    AutomationTriggerRecurringType["BIWEEKLY"] = "BIWEEKLY";
})(AutomationTriggerRecurringType || (AutomationTriggerRecurringType = {}));
export var AutomationTriggerRecurringDayOfMonth;
(function (AutomationTriggerRecurringDayOfMonth) {
    AutomationTriggerRecurringDayOfMonth["START_OF_MONTH"] = "START_OF_MONTH";
    AutomationTriggerRecurringDayOfMonth["MIDDLE_OF_MONTH"] = "MIDDLE_OF_MONTH";
    AutomationTriggerRecurringDayOfMonth["END_OF_MONTH"] = "END_OF_MONTH";
})(AutomationTriggerRecurringDayOfMonth || (AutomationTriggerRecurringDayOfMonth = {}));
export var WhichShift;
(function (WhichShift) {
    WhichShift["FIRST"] = "FIRST";
    WhichShift["LAST"] = "LAST";
})(WhichShift || (WhichShift = {}));
export var SplitShiftDetailTimezoneType;
(function (SplitShiftDetailTimezoneType) {
    SplitShiftDetailTimezoneType["ACCOUNT"] = "ACCOUNT";
    SplitShiftDetailTimezoneType["SHIFT"] = "SHIFT";
})(SplitShiftDetailTimezoneType || (SplitShiftDetailTimezoneType = {}));
export var AutomationListType;
(function (AutomationListType) {
    AutomationListType["ACCOUNT"] = "ACCOUNT";
    AutomationListType["TEMPLATES"] = "TEMPLATES";
})(AutomationListType || (AutomationListType = {}));
