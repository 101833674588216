import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Form, Input, Modal, Checkbox, Switch, Select, InputNumber } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { isInternalUserSelector } from 'src/state/selectors/withAccountDetailState';
import { pdfTemplateTypeOptions, pdfExportTypeOptions, TEMPLATE_FORM_FIELD_LABEL_CLASS, ExportPdfTemplateType, } from '../Constant';
import './styles/PdfExportTemplateConfigModal.scss';
import { usePdfExportTemplateConfigModal, } from './hooks/usePdfExportTemplateConfigModal';
import LinkedDataTemplateForm from './components/LinkedDataTemplateForm/LinkedDataTemplateForm';
var TextArea = Input.TextArea;
var Option = Select.Option;
var CheckboxGroup = Checkbox.Group;
var PdfExportTemplateConfigModal = function (props) {
    var onCloseModal = props.onCloseModal, existingTemplateData = props.existingTemplateData;
    var pdfExportTemplateConfigModalState = usePdfExportTemplateConfigModal(props);
    var form = pdfExportTemplateConfigModalState.form, isLocationInvoiceTemplate = pdfExportTemplateConfigModalState.isLocationInvoiceTemplate, isLocationSummaryOnly = pdfExportTemplateConfigModalState.isLocationSummaryOnly, isDefaultTemplate = pdfExportTemplateConfigModalState.isDefaultTemplate, isLocationSummaryTemplate = pdfExportTemplateConfigModalState.isLocationSummaryTemplate, isShiftDetailTemplate = pdfExportTemplateConfigModalState.isShiftDetailTemplate, isLinkedDataTemplate = pdfExportTemplateConfigModalState.isLinkedDataTemplate, pdfTemplateType = pdfExportTemplateConfigModalState.pdfTemplateType, setPdfTemplateType = pdfExportTemplateConfigModalState.setPdfTemplateType, shifTableSchemas = pdfExportTemplateConfigModalState.shifTableSchemas, shiftTableMetrics = pdfExportTemplateConfigModalState.shiftTableMetrics, templateFormInitialValues = pdfExportTemplateConfigModalState.templateFormInitialValues, showSchemasAndMetricsCheckBoxGroup = pdfExportTemplateConfigModalState.showSchemasAndMetricsCheckBoxGroup, setIsLocationSummaryOnly = pdfExportTemplateConfigModalState.setIsLocationSummaryOnly, setShowSchemasAndMetricsCheckBoxGroup = pdfExportTemplateConfigModalState.setShowSchemasAndMetricsCheckBoxGroup, onSaveTemplate = pdfExportTemplateConfigModalState.onSaveTemplate, onDeleteTemplate = pdfExportTemplateConfigModalState.onDeleteTemplate;
    // TEMPORARY: Using in place of FF, remove when we want to ship linked data template
    var isInternalUser = useRecoilValue(isInternalUserSelector);
    var templateTypeOptions = isInternalUser
        ? pdfTemplateTypeOptions
        : pdfTemplateTypeOptions.filter(function (option) { return option.value !== ExportPdfTemplateType.LINKED_DATA; });
    var modalFooter = useMemo(function () { return (_jsxs("div", { className: "mx-5 flex ".concat(existingTemplateData ? 'justify-between' : 'justify-end'), children: [existingTemplateData && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this template ?", onConfirm: onDeleteTemplate, placement: "top", children: _jsx(Button, { className: "icon-text-btn DeleteButton", icon: _jsx(DeleteOutlined, { className: "mb-1 ml-px" }), children: "Delete" }) })), _jsxs("div", { children: [_jsx(Button, { className: "bg-gray-60 text-black hover:bg-gray-60 hover:text-black ", onClick: onCloseModal, children: "Cancel" }), _jsx(Button, { className: "bg-black text-white focus:bg-black focus:text-white hover:bg-black hover:text-white", onClick: onSaveTemplate, children: "Save" })] })] })); }, [existingTemplateData, onCloseModal, onDeleteTemplate, onSaveTemplate]);
    return (_jsxs(Modal, { className: "PdfTemplateConfigModal", open: true, centered: true, footer: modalFooter, closable: false, width: 600, children: [_jsx("div", { className: "px-9 pt-6 text-lg font-semibold", children: "".concat(existingTemplateData ? 'Edit' : 'Create New', " PDF Template") }), _jsx("div", { className: "TemplateConfigForm", children: _jsxs(Form, { form: form, labelCol: { span: 6 }, wrapperCol: { span: 24 }, layout: "vertical", requiredMark: 'optional', preserve: false, scrollToFirstError: true, initialValues: templateFormInitialValues, children: [_jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: " Template Name" }), name: "templateName", rules: [
                                {
                                    required: true,
                                    message: 'Please enter template name.',
                                },
                            ], children: _jsx(Input, { placeholder: "Enter template name here", autoFocus: true }) }), _jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "PDF Template Type" }), name: "templateType", rules: [
                                {
                                    required: true,
                                    message: 'Please select one type.',
                                },
                            ], children: _jsx(Select, { className: "w-full", defaultValue: pdfTemplateType, value: pdfTemplateType, onChange: setPdfTemplateType, children: templateTypeOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }) }) }), !isLocationInvoiceTemplate && (_jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Group By" }), name: "groupByType", rules: [
                                {
                                    required: true,
                                    message: 'Please select one type.',
                                },
                            ], children: _jsx(Select, { className: "w-full", children: pdfExportTypeOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }) }) })), isDefaultTemplate && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Account Logo" }), required: true, name: "accountLogo", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) }), _jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Pay Summary" }), required: true, name: "payMetrics", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) })] })), isLocationInvoiceTemplate && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Summary Only" }), required: true, name: "summaryOnly", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, { onChange: setIsLocationSummaryOnly }) }), !isLocationSummaryOnly && (_jsxs(Form.Item, { label: _jsx("div", { className: "ml-auto ".concat(TEMPLATE_FORM_FIELD_LABEL_CLASS), children: "Tax Rate" }), name: "taxRate", rules: [
                                        {
                                            required: true,
                                            message: 'Please enter a tax rate.',
                                        },
                                    ], initialValue: 0, children: [_jsx(InputNumber, { className: "ml-auto w-[100px]", defaultValue: templateFormInitialValues.taxRate, min: 0, max: 100, step: 1, onChange: function (value) {
                                                return form.setFieldsValue({ taxRate: value !== null && value !== void 0 ? value : undefined });
                                            } }), _jsx("span", { className: "font-medium", children: " %" })] }))] })), !isLocationSummaryTemplate && !isLinkedDataTemplate && (_jsxs("div", { className: "TableInfoConfigWrapper", children: [_jsx(Form.Item, { name: "shiftTable", label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "List of Shift" }), className: "TableSwitch", required: true, valuePropName: "checked", children: isDefaultTemplate && (_jsx(Switch, { onChange: function (status) {
                                            return setShowSchemasAndMetricsCheckBoxGroup(status);
                                        } })) }), (showSchemasAndMetricsCheckBoxGroup ||
                                    isShiftDetailTemplate ||
                                    isLocationInvoiceTemplate) && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "shiftTableSchemas", children: _jsx(CheckboxGroup, { className: "PropertiesCheckboxGroup", options: shifTableSchemas }) }), _jsx(Form.Item, { name: "shiftTableMetrics", className: "mb-0", children: _jsx(CheckboxGroup, { className: "PropertiesCheckboxGroup", options: shiftTableMetrics }) })] }))] })), isDefaultTemplate && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Time Off Table" }), required: true, name: "timeOffTable", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) }), _jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Optional Text" }), name: "textContent", children: _jsx(TextArea, { placeholder: "Enter text content here" }) }), _jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Attestation Info" }), required: true, name: "attestationInfo", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) }), _jsx(Form.Item, { label: _jsx("div", { className: TEMPLATE_FORM_FIELD_LABEL_CLASS, children: "Signature" }), required: true, name: "signature", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) })] })), isLinkedDataTemplate && (_jsx(LinkedDataTemplateForm, { form: form, existingTemplateData: existingTemplateData, disableReportDate: true }))] }) })] }));
};
export default React.memo(PdfExportTemplateConfigModal);
